import React from 'react';
import styled from 'styled-components/macro';

import Card from '../@setproduct-ui/core/Card';
import Divider from '../@setproduct-ui/core/Divider';

import { FlexColumnContainer, FlexRowContainer } from '../Themed';

export const FormContainer = styled(FlexColumnContainer.withComponent('div'))`
  margin: 20px auto 0 auto;
  width: 90%;
  height: auto;
  min-height: 300px;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
`;

export const FormRow = styled(FlexRowContainer.withComponent('div'))`
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

export const FormColumn = styled(FlexColumnContainer.withComponent('div'))`
  width: 100%;
  justify-content: center !important;
  align-items: flex-start;
  position: relative;
`;

export const FormRowAlt = styled(FlexRowContainer.withComponent('div'))`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;

  & > div.input-wrapper {
    margin-right: 20px;
  }
`;

const FormSectionCardWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  & > #form-section-card {
    width: 100%;
    padding: 10px;
  }
`;

export const FormSectionCard = (props) => {
  return (
    <FormSectionCardWrapper>
      <Card id="form-section-card" color="default" view="raised">
        {props.children}
      </Card>
    </FormSectionCardWrapper>
  );
};

export const FormDivider = () => {
  return (
    <Divider
      color="default"
      colorStep={10}
      size={1}
      style={{
        width: '100%',
        marginBottom: 10,
      }}
    />
  );
};
