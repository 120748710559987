import * as Yup from 'yup';

import { unofficialEmailProvidersPattern } from './regexPatterns';

export const validateOfficialEmail = (email) =>
  new Promise((resolve, reject) => {
    const schema = Yup.string()
      .email('Invalid email address')
      .required('Email address is required')
      .test(
        'Official Email',
        'Enter your official email address',
        (value) => !unofficialEmailProvidersPattern.test(value.split('@')[1])
      );

    schema
      .validate(email)
      .then((valid) => {
        resolve(valid);
      })
      .catch((err) => reject(err.errors[0]));
  });
