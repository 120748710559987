import React from 'react';
import Modal from 'react-modal';

import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import DateRangePicker from '../../@setproduct-ui/core/DateTime/DateRangePicker';

Modal.setAppElement('body');

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
  },
  content: {
    position: 'absolute',
    display: 'block',
    top: 'calc(50vh - 150px)',
    left: 'calc(50vw - 400px)',
    right: 'auto',
    bottom: 'auto',
    width: '800px',
    height: '300px',
    backgroundColor: 'transparent',
    borderRadius: '13px',
    padding: 0,
    border: 'none',
    outline: 'none',
    zIndex: 150,
  },
};

export const DateFilter = ({
  showDateFilter = false,
  onDateRangeChange,
  onClose,
}) => {
  return (
    <Modal
      isOpen={showDateFilter}
      contentLabel="Date Filter Modal"
      style={modalStyles}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          margin: '0 auto',
        }}
      >
        <DateRangePicker
          view="smooth"
          color="primary"
          allowSingleDayRange={false}
          shortcuts={true}
          singleMonthOnly={false}
          onChange={(dateRange) => {
            if (dateRange[0] && dateRange[1]) {
              onDateRangeChange(dateRange);
              onClose();
            }
          }}
        />
      </div>
    </Modal>
  );
};
