import React from 'react';
import styled from 'styled-components/macro';

import { Icon } from '@blueprintjs/core';

import { FlexColumnContainer } from '../Themed';

import Tooltip from '../@setproduct-ui/core/Tooltip';

const BusinessTypeCardWrapper = styled(
  FlexColumnContainer.withComponent('div')
)`
  width: 210px;
  height: 120px;
  background-color: #fff;
  border: ${(props) =>
    props.isSelected ? '1px solid #df5590' : '0.5px solid #f4f4f4'};
  border-radius: 6px;
  box-shadow: 0px 2.3px 5px rgba(173, 173, 173, 0.3);

  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: border 0.1s ease-in;

  & > img {
    width: 64px;
    height: 64px;
    margin-top: 10px;
  }

  & > h1 {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
  }
`;

export const BusinessTypeCard = ({
  isSelected = false,
  thumbnail,
  cardTitle,
  cardInfo = '',
  onCardClick,
}) => {
  const content = React.useMemo(
    () => (
      <p
        style={{
          width: 200,
          textAlign: 'center',
        }}
      >
        <strong>{cardInfo}</strong>
      </p>
    ),
    [cardInfo]
  );

  return (
    <BusinessTypeCardWrapper isSelected={isSelected} onClick={onCardClick}>
      <img src={thumbnail} alt="" />
      <h1>
        {cardTitle}&nbsp;&nbsp;
        <Tooltip
          color="default"
          view="smooth"
          content={content}
          position="auto"
        >
          <Icon icon="info-sign" iconSize="16" />
        </Tooltip>
      </h1>
    </BusinessTypeCardWrapper>
  );
};
