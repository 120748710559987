import { axiosInstance } from './axiosInstance';
import { authService } from '../services';

axiosInstance.interceptors.request.use((req) => {
  const token = localStorage.getItem('accessToken');
  req.headers.Authorization = token ? `Bearer ${token}` : '';

  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err?.response?.status === 401) {
      authService.logOut();
    }
    throw err;
  }
);
