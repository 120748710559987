import * as Yup from 'yup';
import { startCase, camelCase } from 'lodash';

import { unofficialEmailProvidersPattern } from './regexPatterns';
import { checkUserExists } from '../api/user';

const nameSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .min(5, `${msgFieldName} cannot be less than 5 characters`)
    .max(255, `${msgFieldName} cannot be more than 255 characters`)
    .required(`${msgFieldName} is required`);
};

const emailSchemaWithoutDuplicateCheck = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .email(`${msgFieldName} must be valid`)
    .required(`${msgFieldName} is required`)
    .test(
      'Official Email',
      'Enter your official email address',
      (value) => !unofficialEmailProvidersPattern.test(value.split('@')[1])
    );
};

const emailSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .email(`${msgFieldName} must be valid`)
    .required(`${msgFieldName} is required`)
    .test(
      'Official Email',
      'Enter your official email address',
      (value) => !unofficialEmailProvidersPattern.test(value.split('@')[1])
    )
    .test('duplicateCheck', `${msgFieldName} already registered`, (value) => {
      return value
        ? checkUserExists({ emailId: value }).then(({ data }) => {
            return !data.userExists;
          })
        : true;
    });
};

const phoneNumberSchemaWithoutDuplicateCheck = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .length(10, `${msgFieldName} should be 10 digits in length`)
    .required(`${msgFieldName} is required`);
};

const phoneNumberSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .length(10, `${msgFieldName} should be 10 digits in length`)
    .required(`${msgFieldName} is required`)
    .test('duplicateCheck', `${msgFieldName} already registered`, (value) => {
      return value && value.length === 10
        ? checkUserExists({ phoneNumber: `+91${value}` }).then(({ data }) => {
            return !data.userExists;
          })
        : true;
    });
};

const businessTypeSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .oneOf(['corporate', 'cargo', 'ota', 'tmc'])
    .required(`${msgFieldName} is required`);
};

const websiteSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .min(5, `${msgFieldName} cannot be less than 5 characters`)
    .max(255, `${msgFieldName} cannot be more than 255 characters`)
    .url(`${msgFieldName} must be a valid URL`)
    .required(`${msgFieldName} is required`);
};

const gstnSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .length(15, `${msgFieldName} should be 15 characters long`)
    .required(`${msgFieldName} is required`);
};

const iataCodeSchema = ({ fieldName }) => {
  const msgFieldName = startCase(camelCase(fieldName));
  return Yup.string()
    .length(3, `${msgFieldName} should be 3 characters long`)
    .required(`${msgFieldName} is required`);
};

const contactSchemaWithoutDuplicateCheck = Yup.object()
  .shape({
    name: nameSchema({ fieldName: 'name' }),
    email: emailSchemaWithoutDuplicateCheck({ fieldName: 'email' }),
    phoneNumber: phoneNumberSchemaWithoutDuplicateCheck({
      fieldName: 'phoneNumber',
    }),
  })
  .required();

const contactSchema = Yup.object()
  .shape({
    name: nameSchema({ fieldName: 'name' }),
  })
  .required();

const gstnDetailsSchema = Yup.object()
  .shape({
    gstNumber: gstnSchema({ fieldName: 'gstNumber' }),
    entityName: nameSchema({ fieldName: 'entityName' }),
  })
  .required();

const userSchemaWithoutDuplicateCheck = contactSchemaWithoutDuplicateCheck
  .concat(
    Yup.object().shape({
      userFunction: Yup.string()
        .oneOf(['tax', 'legal', 'travel', 'others'])
        .required('Function is required'),
      otherUserFunction: Yup.string().when('userFunction', {
        is: 'others',
        then: Yup.string().required('Must specify your function'),
      }),
    })
  )
  .required();

const userSchema = contactSchema
  .concat(
    Yup.object().shape({
      userFunction: Yup.string()
        .oneOf(['tax', 'legal', 'travel', 'others'])
        .required('Function is required'),
      otherUserFunction: Yup.string().when('userFunction', {
        is: 'others',
        then: Yup.string().required('Must specify your function'),
      }),
    })
  )
  .required();

export const userSignupValidationSchema = Yup.object()
  .shape({
    fullName: nameSchema({ fieldName: 'name' }),
    emailId: emailSchema({ fieldName: 'emailId' }),
    mobileNumber: phoneNumberSchema({ fieldName: 'mobileNumber' }),
    businessType: businessTypeSchema({ fieldName: 'businessType' }),
  })
  .required();

export const alternateContactsFormValidationSchema = Yup.object()
  .shape({
    taxPersonnel: Yup.array().of(contactSchema).min(1).required(),
    travelPersonnel: Yup.array().of(contactSchema).min(1).required(),
    travelManagement: Yup.array().of(contactSchema).min(1).required(),
  })
  .required();

export const clientDetailsFormValidationSchema = Yup.object().shape({
  clientDetails: Yup.array()
    .of(
      Yup.object()
        .shape({
          name: nameSchema({ fieldName: 'name' }),
          gstNumber: gstnSchema({ fieldName: 'gstNumber' }),
        })
        .required()
    )
    .min(1)
    .required(),
});

export const tmcDetailsFormValidationSchema = Yup.object()
  .shape({
    tmcDetails: Yup.array()
      .of(
        Yup.object().shape({
          tmcName: nameSchema({ fieldName: 'tmcName' }),
          contactDetails: Yup.array().of(contactSchema).required(),
        })
      )
      .min(1)
      .required(),
  })
  .required();

export const type1CompanyDetailsFormValidationSchema = Yup.object()
  .shape({
    companyName: nameSchema({ fieldName: 'companyName' }),
    companyWebsite: websiteSchema({ fieldName: 'companyWebsite ' }),
    gstDetails: Yup.array().of(gstnDetailsSchema).required(),
  })
  .required();

export const type2CompanyDetailsFormValidationSchema = Yup.object()
  .shape({
    companyName: nameSchema({ fieldName: 'companyName' }),
    companyWebsite: websiteSchema({ fieldName: 'companyWebsite ' }),
    gstDetails: Yup.array()
      .of(
        gstnDetailsSchema.concat(
          Yup.object()
            .shape({
              iataCode: iataCodeSchema({ fieldName: 'iataCode' }),
            })
            .required()
        )
      )
      .min(1)
      .required(),
  })
  .required();

export const type1UserDetailsFormValidationSchema = userSchemaWithoutDuplicateCheck;

export const type2UserDetailsFormValidationSchema = Yup.object()
  .shape({
    mainUser: userSchemaWithoutDuplicateCheck,
    escalationUsers: Yup.array().of(userSchema).min(1).required(),
    alternateUsers: Yup.array().of(userSchema).min(1).required(),
  })
  .required();

export const validateEmailField = (isNew) => async (value) => {
  if (isNew) {
    return emailSchema({ fieldName: 'email' })
      .validate(value)
      .catch((err) => {
        return err.errors[0];
      });
  } else {
    return undefined;
  }
};

export const validatePhoneNumberField = (isNew) => async (value) => {
  if (isNew) {
    return phoneNumberSchema({ fieldName: 'phoneNumber' })
      .validate(value)
      .catch((err) => {
        return err.errors[0];
      });
  } else {
    return undefined;
  }
};
