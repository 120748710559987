import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
  FormTextInput,
  FormContainer,
  FormRow,
  FormRowAlt,
  Delete,
  AddButton,
  SubmitButton,
} from '../../../components';
import { type2CompanyDetailsFormValidationSchema } from '../../../utils';
import { submitType2CompanyDetailsForm } from '../../../api';

const getGstDetailsObj = () => ({
  uuid: uuidv4(),
  iataCode: '',
  entityName: '',
  gstNumber: '',
  new: true,
});

const initialValues = {
  companyName: '',
  companyWebsite: '',
  gstDetails: [getGstDetailsObj()],
  new: true,
};

export const Type2CompanyDetailsForm = ({
  formValue,
  onSuccess,
  onError,
  ...props
}) => {
  const initialFormValues = React.useMemo(() => {
    return formValue?.companyDetails &&
      Object.keys(formValue.companyDetails).length
      ? formValue.companyDetails
      : initialValues;
  }, [formValue]);

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const { data } = await submitType2CompanyDetailsForm({ form: values });
      console.log(data);
      onSuccess({ msg: 'Saved!' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={type2CompanyDetailsFormValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, submitForm }) => (
        <Form key={values._id || 'form'}>
          <FormContainer>
            <FormRowAlt>
              <Field
                name="companyName"
                label="Company Name:"
                component={FormTextInput}
                readOnly={values?.new !== true}
              />
              <Field
                name="companyWebsite"
                label="Company Website:"
                component={FormTextInput}
                readOnly={values?.new !== true}
              />
            </FormRowAlt>
            <FieldArray name="gstDetails">
              {({ insert, remove }) => {
                return values.gstDetails.map((value, i) => (
                  <React.Fragment key={value.uuid}>
                    <FormRowAlt>
                      <Field
                        name={`gstDetails.${i}.iataCode`}
                        label="IATA Code"
                        component={FormTextInput}
                        readOnly={value?.new !== true}
                      />
                      <Field
                        name={`gstDetails.${i}.entityName`}
                        label="Entity Name:"
                        component={FormTextInput}
                        readOnly={value?.new !== true}
                      />
                      {values.gstDetails.length > 1 && value?.new === true && (
                        <Delete idx={i} remove={remove} />
                      )}
                    </FormRowAlt>
                    <FormRowAlt>
                      <Field
                        name={`gstDetails.${i}.gstNumber`}
                        label="GST Number:"
                        component={FormTextInput}
                        readOnly={value?.new !== true}
                      />
                    </FormRowAlt>
                    {values.gstDetails.length === i + 1 && (
                      <FormRowAlt>
                        <AddButton
                          idx={i}
                          insert={insert}
                          btnText="Add GST Details"
                          rowObj={getGstDetailsObj()}
                        />
                      </FormRowAlt>
                    )}
                  </React.Fragment>
                ));
              }}
            </FieldArray>
            <FormRow>
              <SubmitButton onClick={submitForm} />
            </FormRow>
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};
