import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  FormContainer,
  FormRow,
  FormRowAlt,
  FormColumn,
  FormTextInput,
  FormRadioButtonGroup,
  Delete,
  AddButton,
  SubmitButton,
  Banner,
  FormSectionCard,
  FormDivider,
} from '../../../components';
import {
  type2UserDetailsFormValidationSchema,
  validateEmailField,
  validatePhoneNumberField,
} from '../../../utils';
import { submitType2UserDetailsForm } from '../../../api';

const radioButtonGroup = [
  {
    key: 'Tax',
    value: 'tax',
  },
  {
    key: 'Legal',
    value: 'legal',
  },
  {
    key: 'Travel',
    value: 'travel',
  },
  {
    key: 'Others',
    value: 'others',
  },
];

const getUserObj = () => ({
  uuid: uuidv4(),
  name: '',
  email: '',
  phoneNumber: '',
  userFunction: '',
  otherUserFunction: '',
  new: true,
});

const initialValues = {
  mainUser: getUserObj(),
  escalationUsers: [getUserObj()],
  alternateUsers: [getUserObj()],
};

export const Type2UserDetailsForm = ({
  userData,
  formValue,
  onSuccess,
  onError,
  ...props
}) => {
  const emailId = useSelector((state) => state.auth.emailId);

  const initialFormValues = React.useMemo(() => {
    return formValue?.userDetails
      ? {
          ...formValue.userDetails,
          ...(!formValue.userDetails?.mainUser && {
            mainUser: {
              ...initialValues.mainUser,
              name: userData.user_metadata.userName,
              email: emailId,
              phoneNumber: userData.user_metadata.phoneNumber.substr(3),
            },
          }),
          ...(!formValue.userDetails.escalationUsers.length && {
            escalationUsers: initialValues.escalationUsers,
          }),
          ...(!formValue.userDetails.alternateUsers.length && {
            alternateUsers: initialValues.alternateUsers,
          }),
        }
      : initialValues;
  }, [formValue, userData, emailId]);

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const { data } = await submitType2UserDetailsForm({ form: values });
      console.log(data);
      onSuccess({ msg: 'Saved!' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={type2UserDetailsFormValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, submitForm }) => (
        <Form key={values._id || 'form'}>
          <FormContainer>
            <FormSectionCard>
              <FormRowAlt>
                <h4>Enter your user details</h4>
              </FormRowAlt>
              <FormRowAlt style={{ alignItems: 'flex-start' }}>
                <FormColumn>
                  <FormRowAlt>
                    <Field
                      name="mainUser.name"
                      label="User Name"
                      component={FormTextInput}
                      readOnly
                    />
                  </FormRowAlt>
                  <FormRowAlt>
                    <Field
                      name="mainUser.email"
                      label="Email"
                      component={FormTextInput}
                      readOnly
                    />
                  </FormRowAlt>
                  <FormRowAlt>
                    <Field
                      name="mainUser.phoneNumber"
                      label="Phone Number"
                      component={FormTextInput}
                      readOnly
                    />
                  </FormRowAlt>
                </FormColumn>
                <FormColumn>
                  <FormRowAlt
                    style={{ alignItems: 'flex-start', marginTop: 5 }}
                  >
                    <FormRadioButtonGroup
                      name="mainUser.userFunction"
                      headingText="Select Function"
                      options={radioButtonGroup}
                      readOnly={values.mainUser?.new !== true}
                    />
                  </FormRowAlt>
                  {values.mainUser.userFunction === 'others' && (
                    <FormRowAlt>
                      <Field
                        name="mainUser.otherUserFunction"
                        label="Other Function"
                        component={FormTextInput}
                        readOnly={values.mainUser?.new !== true}
                      />
                    </FormRowAlt>
                  )}
                </FormColumn>
              </FormRowAlt>
            </FormSectionCard>
            <FieldArray name="escalationUsers">
              {({ insert, remove }) => (
                <>
                  <FormSectionCard>
                    <FormRowAlt>
                      <h4>Contact Details for escalations and approvals</h4>
                    </FormRowAlt>
                    <FormRow>
                      <Banner bannerText="The below contact detail(s) will be used for any escalations or approvals." />
                    </FormRow>
                    {values.escalationUsers.map((value, i) => (
                      <React.Fragment key={value.uuid}>
                        <FormRowAlt style={{ alignItems: 'flex-start' }}>
                          <FormColumn>
                            <FormRowAlt>
                              <Field
                                name={`escalationUsers.${i}.name`}
                                label="User Name"
                                component={FormTextInput}
                                readOnly={value?.new !== true}
                              />
                            </FormRowAlt>
                            <FormRowAlt>
                              <Field
                                name={`escalationUsers.${i}.email`}
                                label="Email"
                                component={FormTextInput}
                                readOnly={value?.new !== true}
                                validate={validateEmailField(value?.new)}
                              />
                            </FormRowAlt>
                            <FormRowAlt>
                              <Field
                                name={`escalationUsers.${i}.phoneNumber`}
                                label="Phone Number"
                                component={FormTextInput}
                                readOnly={value?.new !== true}
                                validate={validatePhoneNumberField(value?.new)}
                              />
                            </FormRowAlt>
                          </FormColumn>
                          <FormColumn>
                            <FormRowAlt
                              style={{ alignItems: 'flex-start', marginTop: 5 }}
                            >
                              <FormRadioButtonGroup
                                name={`escalationUsers.${i}.userFunction`}
                                headingText="Select Function"
                                options={radioButtonGroup}
                                readOnly={value?.new !== true}
                              />
                              {values.escalationUsers.length > 1 &&
                                value?.new === true && (
                                  <Delete idx={i} remove={remove} />
                                )}
                            </FormRowAlt>
                            {values.escalationUsers[i].userFunction ===
                              'others' && (
                              <FormRowAlt>
                                <Field
                                  name={`escalationUsers.${i}.otherUserFunction`}
                                  label="Other Function"
                                  component={FormTextInput}
                                  readOnly={value?.new !== true}
                                />
                              </FormRowAlt>
                            )}
                          </FormColumn>
                        </FormRowAlt>
                        <FormDivider />
                        {values.escalationUsers.length === i + 1 && (
                          <FormRowAlt
                            style={{
                              marginTop: 10,
                            }}
                          >
                            <AddButton
                              idx={i}
                              insert={insert}
                              btnText="Add User"
                              rowObj={getUserObj()}
                            />
                          </FormRowAlt>
                        )}
                      </React.Fragment>
                    ))}
                  </FormSectionCard>
                </>
              )}
            </FieldArray>
            <FieldArray name="alternateUsers">
              {({ insert, remove }) => (
                <>
                  <FormSectionCard>
                    <FormRowAlt>
                      <h4>
                        Alternate contacts for any GST mismatches or data
                        recovery
                      </h4>
                    </FormRowAlt>
                    <FormRow>
                      <Banner bannerText="The below contact detail(s) will be used  in case of any mismatches or data recovery" />
                    </FormRow>
                    {values.alternateUsers.map((value, i) => (
                      <React.Fragment key={value.uuid}>
                        <FormRowAlt style={{ alignItems: 'flex-start' }}>
                          <FormColumn>
                            <FormRowAlt>
                              <Field
                                name={`alternateUsers.${i}.name`}
                                label="User Name"
                                component={FormTextInput}
                                readOnly={value?.new !== true}
                              />
                            </FormRowAlt>
                            <FormRowAlt>
                              <Field
                                name={`alternateUsers.${i}.email`}
                                label="Email"
                                component={FormTextInput}
                                readOnly={value?.new !== true}
                                validate={validateEmailField(value?.new)}
                              />
                            </FormRowAlt>
                            <FormRowAlt>
                              <Field
                                name={`alternateUsers.${i}.phoneNumber`}
                                label="Phone Number"
                                component={FormTextInput}
                                readOnly={value?.new !== true}
                                validate={validatePhoneNumberField(value?.new)}
                              />
                            </FormRowAlt>
                          </FormColumn>
                          <FormColumn>
                            <FormRowAlt
                              style={{ alignItems: 'flex-start', marginTop: 5 }}
                            >
                              <FormRadioButtonGroup
                                name={`alternateUsers.${i}.userFunction`}
                                headingText="Select Function"
                                options={radioButtonGroup}
                                readOnly={value?.new !== true}
                              />
                              {values.alternateUsers.length > 1 &&
                                value?.new === true && (
                                  <Delete idx={i} remove={remove} />
                                )}
                            </FormRowAlt>
                            {values.alternateUsers[i].userFunction ===
                              'others' && (
                              <FormRowAlt>
                                <Field
                                  name={`alternateUsers.${i}.otherUserFunction`}
                                  label="Other Function"
                                  component={FormTextInput}
                                  readOnly={value?.new !== true}
                                />
                              </FormRowAlt>
                            )}
                          </FormColumn>
                        </FormRowAlt>
                        <FormDivider />
                        {values.alternateUsers.length === i + 1 && (
                          <FormRowAlt style={{ marginTop: 10 }}>
                            <AddButton
                              idx={i}
                              insert={insert}
                              btnText="Add User"
                              rowObj={getUserObj()}
                            />
                          </FormRowAlt>
                        )}
                      </React.Fragment>
                    ))}
                  </FormSectionCard>
                </>
              )}
            </FieldArray>
            <FormRow>
              <SubmitButton onClick={submitForm} />
            </FormRow>
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};
