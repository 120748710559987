import React from 'react';
import Select from 'react-select';

const options = new Array(31)
  .fill()
  .map((_, i) => ({ value: i + 1, label: `${i + 1}` }));

export const DaySelect = ({ value, onDaySelect }) => {
  return (
    <div className="select-wrapper" style={{ width: 150 }}>
      <Select
        placeholder="Select Day"
        classNamePrefix="select"
        value={value || null}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        options={options}
        onChange={onDaySelect}
      />
    </div>
  );
};
