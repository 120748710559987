import { axiosInstance } from './axiosInstance';

export const fetchBookingData = async () => {
  const url = 'https://staging.gstinput.com/hotel/booking-data';

  return axiosInstance.get(url).then(({ data }) => data.data);
};

export const uploadBookingDataFile = ({ file }) => {
  const form = new FormData();
  form.append('doc', file);

  const url = 'https://staging.gstinput.com/hotel/booking-data';

  return axiosInstance.post(url, file);
};

export const fetchInvoiceData = () => {
  const url = 'https://staging.gstinput.com/hotel/invoice-data';

  return axiosInstance.get(url);
};

export const setInvoiceData = ({ data }) => {
  const url = 'https://staging.gstinput.com/hotel/invoice-data';

  const body = {
    empName: data.empName || '',
    supplierGSTIN: data.supplierGSTIN || '',
    recipientGSTIN: data.recipientGSTIN || '',
    checkIn: data.checkIn || '',
    checkOut: data.checkOut || '',
    hotelName: data.hotelName || '',
    hotelAddress: data.hotelAddress || '',
    invoiceNumber: data.invoiceNumber || '',
    invoiceDate: data.invoiceDate || '',
    accomodationTaxable: data.roomTaxableValue || '',
    accomodationCGST: data.roomCgstAmount || '',
    accomodationSGST: data.roomSgstAmount || '',
    foodTaxable: data.foodTaxableValue || '',
    foodCGST: data.foodCgstAmount || '',
    foodSGST: data.foodSgstAmount || '',
    miscTaxable: data.miscTaxableValue || '',
    miscCGST: data.miscCgstAmount || '',
    miscSGST: data.miscSgstAmount || '',
    nonTaxable: data.nonTaxableValue || '',
    invoiceTotal: data.totalAmount || '',
  };

  return axiosInstance.post(url, body);
};

export const getInvoiceOCR = ({
  file,
  recipientGSTIN = '',
  supplierGSTIN = '',
  taxValue = '',
  invoiceNumber = '',
}) => {
  const form = new FormData();
  form.append('file', file);
  form.append('customer_GSTN', recipientGSTIN);
  form.append('supplier_GSTN', supplierGSTIN);
  form.append('amount', taxValue);
  form.append('invoice_number', invoiceNumber);

  const url = 'https://staging.gstinput.com/hotel/invoice-ocr';

  return axiosInstance.post(url, form);
};
