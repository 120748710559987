import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
  FormContainer,
  FormRow,
  FormRowAlt,
  FormTextInput,
  Delete,
  AddButton,
  SubmitButton,
  Banner,
  FormSectionCard,
  FormDivider,
} from '../../../components';
import {
  alternateContactsFormValidationSchema,
  validateEmailField,
  validatePhoneNumberField,
} from '../../../utils';
import { submitAlternateContactsForm } from '../../../api';

const getUserObj = () => ({
  uuid: uuidv4(),
  name: '',
  email: '',
  phoneNumber: '',
  userFunction: '',
  otherUserFunction: '',
  new: true,
});

const initialValues = {
  taxPersonnel: [{ ...getUserObj(), userFunction: 'tax' }],
  travelPersonnel: [{ ...getUserObj(), userFunction: 'travel' }],
  travelManagement: [{ ...getUserObj(), userFunction: 'travel' }],
};

export const AlternateContactsForm = ({
  formValue,
  onSuccess,
  onError,
  ...props
}) => {
  const initialFormValues = React.useMemo(() => {
    return formValue?.alternateContacts
      ? {
          ...formValue.alternateContacts,
          ...(formValue.alternateContacts.taxPersonnel.length === 0 && {
            taxPersonnel: initialValues.taxPersonnel,
          }),
          ...(formValue.alternateContacts.travelPersonnel.length === 0 && {
            travelPersonnel: initialValues.travelPersonnel,
          }),
          ...(formValue.alternateContacts.travelManagement.length === 0 && {
            travelManagement: initialValues.travelManagement,
          }),
        }
      : initialValues;
  }, [formValue]);

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const { data } = await submitAlternateContactsForm({ form: values });
      console.log(data);
      onSuccess({ msg: 'Saved!' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={alternateContactsFormValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, submitForm }) => (
        <Form key={values._id || 'form'}>
          <FormContainer>
            <FormRow>
              <Banner
                bannerText={`Report unusual activities/fraud from your account \n Change login details if the admin leaves the organization`}
              />
            </FormRow>
            <FieldArray name="taxPersonnel">
              {({ insert, remove }) => (
                <>
                  <FormSectionCard>
                    <FormRowAlt>
                      <h4>Tax Personnel Contacts</h4>
                    </FormRowAlt>
                    {values.taxPersonnel.map((value, i) => (
                      <React.Fragment key={value.uuid}>
                        <FormRowAlt>
                          <Field
                            name={`taxPersonnel.${i}.name`}
                            label="User Name"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                          />
                          <Field
                            name={`taxPersonnel.${i}.email`}
                            label="Email"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validateEmailField(value?.new)}
                          />
                          {values.taxPersonnel.length > 1 &&
                            value?.new === true && (
                              <Delete idx={i} remove={remove} />
                            )}
                        </FormRowAlt>
                        <FormRowAlt>
                          <Field
                            name={`taxPersonnel.${i}.phoneNumber`}
                            label="Phone Number"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validatePhoneNumberField(value?.new)}
                          />
                        </FormRowAlt>
                        <FormDivider />
                        {values.taxPersonnel.length === i + 1 && (
                          <FormRowAlt>
                            <AddButton
                              idx={i}
                              insert={insert}
                              btnText="Add User"
                              rowObj={{ ...getUserObj(), userFunction: 'tax' }}
                            />
                          </FormRowAlt>
                        )}
                      </React.Fragment>
                    ))}
                  </FormSectionCard>
                </>
              )}
            </FieldArray>
            <FieldArray name="travelPersonnel">
              {({ insert, remove }) => (
                <>
                  <FormSectionCard>
                    <FormRowAlt>
                      <h4>Travel Personnel Contacts</h4>
                    </FormRowAlt>
                    {values.travelPersonnel.map((value, i) => (
                      <React.Fragment key={value.uuid}>
                        <FormRowAlt>
                          <Field
                            name={`travelPersonnel.${i}.name`}
                            label="User Name"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                          />
                          <Field
                            name={`travelPersonnel.${i}.email`}
                            label="Email"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validateEmailField(value?.new)}
                          />
                          {values.travelPersonnel.length > 1 &&
                            value?.new === true && (
                              <Delete idx={i} remove={remove} />
                            )}
                        </FormRowAlt>
                        <FormRowAlt>
                          <Field
                            name={`travelPersonnel.${i}.phoneNumber`}
                            label="Phone Number"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validatePhoneNumberField(value?.new)}
                          />
                        </FormRowAlt>
                        <FormDivider />
                        {values.travelPersonnel.length === i + 1 && (
                          <FormRowAlt>
                            <AddButton
                              idx={i}
                              insert={insert}
                              btnText="Add User"
                              rowObj={{
                                ...getUserObj(),
                                userFunction: 'travel',
                              }}
                            />
                          </FormRowAlt>
                        )}
                      </React.Fragment>
                    ))}
                  </FormSectionCard>
                </>
              )}
            </FieldArray>
            <FieldArray name="travelManagement">
              {({ insert, remove }) => (
                <>
                  <FormSectionCard>
                    <FormRowAlt>
                      <h4>Travel Management Contacts</h4>
                    </FormRowAlt>
                    {values.travelManagement.map((value, i) => (
                      <React.Fragment key={value.uuid}>
                        <FormRowAlt>
                          <Field
                            name={`travelManagement.${i}.name`}
                            label="User Name"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                          />
                          <Field
                            name={`travelManagement.${i}.email`}
                            label="Email"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validateEmailField(value?.new)}
                          />
                          {values.travelManagement.length > 1 &&
                            value?.new === true && (
                              <Delete idx={i} remove={remove} />
                            )}
                        </FormRowAlt>
                        <FormRowAlt>
                          <Field
                            name={`travelManagement.${i}.phoneNumber`}
                            label="Phone Number"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validatePhoneNumberField(value?.new)}
                          />
                        </FormRowAlt>
                        <FormDivider />
                        {values.travelManagement.length === i + 1 && (
                          <FormRowAlt>
                            <AddButton
                              idx={i}
                              insert={insert}
                              btnText="Add User"
                              rowObj={{
                                ...getUserObj(),
                                userFunction: 'travel',
                              }}
                            />
                          </FormRowAlt>
                        )}
                      </React.Fragment>
                    ))}
                  </FormSectionCard>
                </>
              )}
            </FieldArray>
            <FormRow>
              <SubmitButton onClick={submitForm} />
            </FormRow>
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};
