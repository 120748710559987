import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';

import { Icon } from '@blueprintjs/core';
import ReactJson from 'react-json-view';
import Button from '../../@setproduct-ui/core/Button';
import Chips from '../../@setproduct-ui/core/Chips';

import { fetchCompany } from '../../../api';

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Launch,
} from '@material-ui/icons';
import usePrevious from '../../../hooks/usePrevious';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Launch: forwardRef((props, ref) => <Launch {...props} ref={ref} />),
};

const CompaniesTableWrapper = styled.div`
  width: 1392px;
  margin: 0 auto 50px auto;

  & > div:nth-of-type(1) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row nowrap;

    .calender-icon {
      margin: 0 0 10px 25px;
      cursor: pointer;
    }
  }

  .table-row {
    width: 100%;

    & > div {
      width: 100% !important;
    }

    tbody.MuiTableBody-root > tr:hover {
      background-color: #fff !important;
    }
  }
`;

export const CompaniesTable = ({
  event,
  openDateFilter,
  onClearFilter,
  displayError,
}) => {
  const tableRef = React.createRef();

  const history = useHistory();
  const previousEvent = usePrevious(event);

  React.useEffect(() => {
    if (previousEvent && !isEqual(previousEvent, event)) {
      tableRef.current && tableRef.current.onQueryChange({ page: 0 });
    }
  }, [previousEvent, event, tableRef]);

  const columns = React.useMemo(
    () => [
      {
        title: 'Company Name',
        field: 'companyName',
        align: 'center',
      },
      {
        title: 'Created On',
        field: 'createdAt',
        align: 'center',
      },
      {
        title: 'Number of PANs',
        field: 'numberOfPANs',
        align: 'center',
      },
      {
        title: 'List of PANs',
        field: 'listOfPANs',
        align: 'center',
      },
      {
        title: 'Business Type',
        field: 'businessType',
        align: 'center',
      },
      {
        title: 'Tags',
        field: 'tags',
      },
    ],
    []
  );

  const data = React.useCallback(
    async (query) => {
      try {
        const page = query.page;
        const pageSize = query.pageSize;

        const { data } = await event.dataMethod({
          ...event.args,
          currentPage: page + 1,
          countPerPage: pageSize,
        });
        return {
          data: data.data.map((obj) => ({
            ...obj,
            tags: getTags(obj.tags),
          })),
          page: data.page - 1,
          totalCount: data.total,
        };
      } catch (error) {
        console.log(error);
        displayError(error.reponse.data.error);
        return {
          data: [],
          page: 0,
          totalCount: 0,
        };
      }
    },
    [event, displayError]
  );

  return (
    <CompaniesTableWrapper>
      <div>
        <Button
          type="default"
          view="smooth"
          color="danger"
          dense={true}
          text="Clear Filters"
          icon="cross"
          rightIcon={false}
          loading={false}
          disabled={false}
          fill={false}
          onClick={onClearFilter}
        />
        <Icon
          className="calender-icon"
          icon="calendar"
          iconSize={32}
          color="#1B4EA3"
          onClick={openDateFilter}
        />
      </div>
      <div className="table-row">
        <MaterialTable
          style={{
            backgroundColor: 'var(--blue10)',
          }}
          title="QA-Finkraft Portal Companies"
          tableRef={tableRef}
          icons={tableIcons}
          columns={columns}
          options={{
            search: false,
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
            sorting: false,
            initialPage: 0,
            headerStyle: {
              backgroundColor: 'var(--blue10)',
            },
            rowStyle: {
              backgroundColor: 'var(--blue0)',
            },
          }}
          data={data}
          actions={[
            {
              icon: () => <Launch />,
              tooltip: 'Open Company',
              onClick: (event, rowData) =>
                history.push(`/admin/companies/${rowData.id}`),
            },
          ]}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          detailPanel={[
            (rowData) => ({
              render: () => <DetailPanel rowData={rowData} />,
            }),
          ]}
        />
      </div>
    </CompaniesTableWrapper>
  );
};

function getTags(tags) {
  const chipProps = {
    type: 'dense',
    color: 'success',
    tag: '',
    withTick: false,
    icon: false,
    rightIcon: null,
    removable: false,
    onRemove: null,
    disabled: false,
    round: false,
    withNumber: null,
    withAvatar: null,
  };

  const chipsForTags = {
    pending: <Chips {...chipProps} color="warning" tag="pending" />,
    approved: <Chips {...chipProps} color="success" tag="approved" />,
    incomplete: <Chips {...chipProps} color="primary" tag="incomplete" />,
  };

  return (
    <div>
      {tags.map((tag, i) => (
        <React.Fragment key={i}>{chipsForTags[tag]}&nbsp;&nbsp;</React.Fragment>
      ))}
    </div>
  );
}

const DetailPanel = ({ rowData }) => {
  const [companyData, setCompanyData] = React.useState(null);
  const [isFetching, setIsFetching] = React.useState(false);
  const [apiError, setApiError] = React.useState('');

  React.useEffect(() => {
    (async function () {
      try {
        setIsFetching(true);
        const { data } = await fetchCompany(rowData.id);
        setCompanyData(data.data);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        setApiError(error.response.data);
        setIsFetching(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (apiError) return <div>{JSON.stringify(apiError)}</div>;

  return (
    !isFetching &&
    companyData && (
      <ReactJson
        src={companyData}
        theme="monokai"
        enableClipboard={false}
        indentWidth="4"
        displayDataTypes={false}
        displayObjectSize={false}
        collapsed={false}
        iconStyle="triangle"
        style={{
          padding: '20px 40px',
        }}
      />
    )
  );
};
