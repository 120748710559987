import { startOfDay, endOfDay } from 'date-fns';

import { axiosInstance } from './axiosInstance';

export const fetchCompany = async (id) => {
  return axiosInstance.get(`admin/companies/${id}`);
};

export const fetchCompanies = async ({
  currentPage = 1,
  countPerPage = 10,
  startDate = new Date('2020-01-01'),
  endDate = new Date(),
}) => {
  const queryParams = {
    startDate: startOfDay(startDate),
    endDate: endOfDay(endDate),
    currentPage,
    countPerPage,
  };
  return axiosInstance.get('admin/companies', { params: queryParams });
};

export const fetchCounts = async () => {
  return axiosInstance.get('admin/counts');
};

export const fetchSearchedCompanies = async ({
  query = '',
  currentPage = 1,
  countPerPage = 10,
}) => {
  const queryParams = {
    query,
    currentPage,
    countPerPage,
  };
  return axiosInstance.get('admin/search', { params: queryParams });
};

export const fetchFilteredCompanies = async ({
  type,
  currentPage = 1,
  countPerPage = 10,
}) => {
  const queryParams = {
    type,
    currentPage,
    countPerPage,
  };
  return axiosInstance.get('admin/companies/filtered', { params: queryParams });
};

export const grantPanAccess = async ({ pan, companyId }) => {
  return axiosInstance.post('admin/access/grant/pan', { pan, id: companyId });
};

export const grantUserAccess = async ({ email, companyId }) => {
  return axiosInstance.post('admin/access/grant/user', {
    email,
    id: companyId,
  });
};

export const revokePanAccess = async ({ pan, companyId }) => {
  return axiosInstance.post('admin/access/revoke/pan', { pan, id: companyId });
};

export const revokeUserAccess = async ({ email, companyId }) => {
  return axiosInstance.post('admin/access/revoke/user', {
    email,
    id: companyId,
  });
};
