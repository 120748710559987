import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components/macro';

import { FlexRowContainer } from '../Themed';
import { FaqCard } from './FaqCard';

import crossIcon from '../../assets/icons/cross.svg';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    overflowY: 'scroll',
  },
  content: {
    width: '80vw',
    height: '80vh',
    position: 'absolute',
    top: '10vh',
    bottom: '10vh',
    left: '10vw',
    right: '10vw',
    backgroundColor: '#fff',
    borderRadius: '13px',
    zIndex: 150,
  },
};

const ModalHeading = styled.h1`
  height: 10%;
  width: 95%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 50px;
  line-height: 68px;
  padding-left: 15px;

  & span {
    float: right;
    padding-right: 15px;
    cursor: pointer;
  }
`;

const FaqContainer = styled(FlexRowContainer.withComponent('div'))`
  height: 90%;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & div {
    margin: 15px;
    min-height: 290px;
    max-height: 350px;
  }
`;

export const FaqModal = React.memo(({ faqs, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="FAQs Modal"
      style={modalStyles}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onRequestClose}
    >
      <ModalHeading>
        FAQ's
        <span onClick={onRequestClose}>
          <img src={crossIcon} alt="" />
        </span>
      </ModalHeading>
      <FaqContainer>
        {faqs.map((faq, i) => (
          <FaqCard key={i} question={faq.question} answer={faq.answer} />
        ))}
      </FaqContainer>
    </Modal>
  );
});
