import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@blueprintjs/core';

import { LoginForm } from '../components/login';

import Toaster from '../components/@setproduct-ui/core/Toast/Toaster';

import illustration from '../assets/images/login-illustration.svg';
import illustrationBg from '../assets/backgrounds/login-gradient-bg.png';

const LoginContainerWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: pink;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: blue;
  background: url(${illustrationBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  & > div {
    margin: 0;
    width: 50%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .form-wrapper {
    background: #f7f7fa;
    border-radius: 0px 16px 16px 0px;

    .form-container {
      width: 460px;
      height: auto;

      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-flow: column nowrap;

      h1 {
        width: 460px;
        height: auto;

        font-size: 32px;
        line-height: 42px;
        color: #1c1d21;
        white-space: pre-line;
      }

      p {
        font-size: 14px;
        line-height: 21px;

        color: #8181a5;
      }
    }
  }

  & > .illustration-wrapper {
    & > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const LoginContainer = (props) => {
  const [emailId, setEmailId] = React.useState(null);
  const [myRef, setRef] = React.useState(null);

  React.useEffect(() => {
    if (props.location.search) {
      const params = new URLSearchParams(props.location.search);
      const email = params.get('emailId');
      setEmailId(email);
    }
  }, [props.location]);

  const onLoginError = React.useCallback(
    (message) => {
      myRef.show({ message, icon: <Icon icon="info-sign" iconSize={24} /> });
    },
    [myRef]
  );

  return (
    <LoginContainerWrapper>
      <div className="form-wrapper">
        <div className="form-container">
          <h1>
            {'Welcome to Finkraft Ninja!\nSign-in to see latest updates.'}
          </h1>
          <p>Enter your details to proceed further</p>
          <div>
            <LoginForm emailId={emailId} onLoginError={onLoginError} />
          </div>
        </div>
      </div>
      <div className="illustration-wrapper">
        <img src={illustration} alt="" />
      </div>
      <Toaster
        type="def"
        view="outlined"
        color="danger"
        usePortal={true}
        position="top"
        ref={(ref) => setRef(ref)}
        maxToasts={2}
        withoutClose={false}
      />
    </LoginContainerWrapper>
  );
};
