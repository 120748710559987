import React from 'react';
import styled from 'styled-components/macro';
import { startCase } from 'lodash';

import { Icon } from '@blueprintjs/core';
import Table from '../../@setproduct-ui/core/Table/Table';

const FormCompletionStatusTableWrapper = styled.div``;

export const FormCompletionStatusTable = ({ formCompletion }) => {
  const head = React.useMemo(
    () => [
      {
        id: 'id',
        label: 'Sr. No.',
      },
      {
        id: 'formName',
        label: 'Form Name',
      },
      {
        id: 'status',
        label: 'Form Status',
      },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      Object.entries(formCompletion).map((arr, i) => ({
        id: i + 1,
        formName: startCase(arr[0]),
        status: {
          0: (
            <span>
              <Icon icon="wrench" iconSize={18} />
              &nbsp;Incomplete
            </span>
          ),
          1: (
            <span>
              <Icon icon="tick-circle" iconSize={18} />
              &nbsp;Complete
            </span>
          ),
        }[arr[1]],
      })),
    [formCompletion]
  );

  return (
    <FormCompletionStatusTableWrapper>
      <Table
        name={head}
        data={data}
        position={new Array(3).fill('center')}
        type="def"
        view="smooth"
        color="primary"
        pages={false}
        checkboxes={false}
        handleRowClick={() => {}}
      />
    </FormCompletionStatusTableWrapper>
  );
};
