import React from 'react';

import Button from '../../@setproduct-ui/core/Button';

export const AccessActionButton = ({
  hasAccess,
  isLoading = false,
  disabled = false,
  grantAccessHandler,
  revokeAccessHandler,
  accessHandlerProps = '',
}) => {
  return (
    <>
      {/* Show opposite button type based on if access present or not. */}
      {!hasAccess ? (
        <Button
          type="default"
          view="filled"
          color="success"
          dense={true}
          text="Grant Access"
          icon="tick"
          rightIcon={false}
          loading={isLoading}
          disabled={disabled}
          fill={false}
          onClick={() => {
            grantAccessHandler(accessHandlerProps);
          }}
        />
      ) : (
        <Button
          type="default"
          view="filled"
          color="danger"
          dense={true}
          text="Revoke Access"
          icon="disable"
          rightIcon={false}
          loading={isLoading}
          disabled={disabled}
          fill={false}
          onClick={() => {
            revokeAccessHandler(accessHandlerProps);
          }}
        />
      )}
    </>
  );
};
