import React from 'react';
import styled from 'styled-components/macro';

import { FlexRowContainer, BusinessTypeCard } from '../components';

import corporateThumbnail from '../assets/images/corporate-thumbnail.svg';
import cargoThumbnail from '../assets/images/cargo-thumbnail.svg';
import otaThumbnail from '../assets/images/ota-thumbnail.svg';
import tmcThumbnail from '../assets/images/tmc-thumbnail.svg';

const CardsContainer = styled(FlexRowContainer)`
  margin: 16px 0 0 0 !important;
  height: 120px;
  justify-content: space-evenly !important;
  align-items: center;
`;

const businessTypes = [
  {
    businessType: 'Corporate',
    businessTypeDesc:
      'Download invoices and upload ticketing data for your business travel',
    thumbnail: corporateThumbnail,
  },
  {
    businessType: 'Cargo',
    businessTypeDesc: 'Download invoices for the cargo shipments',
    thumbnail: cargoThumbnail,
  },
  {
    businessType: 'Online Travel Agent',
    businessTypeDesc: 'Upload/Download TCS data from the portal',
    thumbnail: otaThumbnail,
  },
  {
    businessType: 'Travel Agent',
    businessTypeDesc:
      'Travel Agents can upload ticketing details for their customers with correct GST details to create correct Tax invoices',
    thumbnail: tmcThumbnail,
  },
];

const businessTypesValuesMapping = {
  corporate: 'corporate',
  cargo: 'cargo',
  'online travel agent': 'ota',
  'travel agent': 'tmc',
};

export const BusinessTypesContainer = ({ selectedCard, onCardClick }) => {
  return (
    <CardsContainer>
      {businessTypes.map((obj, i) => (
        <BusinessTypeCard
          key={i}
          isSelected={
            selectedCard ===
            businessTypesValuesMapping[obj.businessType.toLowerCase()]
          }
          thumbnail={obj.thumbnail}
          cardTitle={obj.businessType}
          cardInfo={obj.businessTypeDesc}
          onCardClick={() =>
            onCardClick(
              businessTypesValuesMapping[obj.businessType.toLowerCase()]
            )
          }
        />
      ))}
    </CardsContainer>
  );
};
