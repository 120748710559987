import React from 'react';
import Select from 'react-select';

const options = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

export const MonthSelect = ({ value, onMonthSelect }) => {
  return (
    <div className="select-wrapper" style={{ width: 170 }}>
      <Select
        placeholder="Select Month"
        classNamePrefix="select"
        value={value || null}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        options={options}
        onChange={onMonthSelect}
      />
    </div>
  );
};
