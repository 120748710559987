import React from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
  FormContainer,
  FormRow,
  FormRowAlt,
  FormTextInput,
  Delete,
  AddButton,
  SubmitButton,
  FormSectionCard,
  FormDivider,
} from '../../../components';
import { tmcDetailsFormValidationSchema } from '../../../utils';
import { submitTmcDetailsForm } from '../../../api';

const getContactDetailObj = () => ({
  uuid: uuidv4(),
  name: '',
  email: '',
  phoneNumber: '',
  new: true,
});

const getTmcDetailObj = () => ({
  uuid: uuidv4(),
  tmcName: '',
  contactDetails: [getContactDetailObj()],
  new: true,
});

const initialValues = {
  tmcDetails: [getTmcDetailObj()],
};

export const TmcDetailsForm = ({ formValue, onSuccess, onError, ...props }) => {
  const initialFormValues = React.useMemo(() => {
    return formValue?.tmcDetails && formValue.tmcDetails.length > 0
      ? { tmcDetails: formValue.tmcDetails }
      : initialValues;
  }, [formValue]);

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const { data } = submitTmcDetailsForm({ form: values });
      console.log(data);
      onSuccess({ msg: 'Saved!' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={tmcDetailsFormValidationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, submitForm }) => (
        <Form key={values._id || 'form'}>
          <FormContainer>
            <FormRowAlt>
              <h4>Your TMC Details</h4>
            </FormRowAlt>
            <FieldArray name="tmcDetails">
              {({ insert, remove }) => {
                return values.tmcDetails.map((value, i) => (
                  <React.Fragment key={value.uuid}>
                    <FormSectionCard>
                      <FormRowAlt
                        style={{
                          justifyContent: 'space-between',
                        }}
                      >
                        <Field
                          name={`tmcDetails.${i}.tmcName`}
                          label="Company Name"
                          component={FormTextInput}
                          readOnly={value?.new !== true}
                        />
                        {values.tmcDetails.length > 1 &&
                          value?.new === true && (
                            <Delete idx={i} remove={remove} />
                          )}
                      </FormRowAlt>
                      <FieldArray name={`tmcDetails.${i}.contactDetails`}>
                        {(arrayHelpers) => {
                          return values.tmcDetails[i].contactDetails.map(
                            (_value, idx) => (
                              <React.Fragment key={_value.uuid}>
                                <FormRowAlt>
                                  <Field
                                    name={`tmcDetails.${i}.contactDetails.${idx}.name`}
                                    label="Name"
                                    component={FormTextInput}
                                    readOnly={_value?.new !== true}
                                  />
                                  <Field
                                    name={`tmcDetails.${i}.contactDetails.${idx}.email`}
                                    label="Email"
                                    component={FormTextInput}
                                    readOnly={_value?.new !== true}
                                  />
                                  {values.tmcDetails[i].contactDetails.length >
                                    1 &&
                                    _value?.new !== true && (
                                      <Delete
                                        idx={idx}
                                        remove={arrayHelpers.remove}
                                      />
                                    )}
                                </FormRowAlt>
                                <FormRowAlt>
                                  <Field
                                    name={`tmcDetails.${i}.contactDetails.${idx}.phoneNumber`}
                                    label="Phone Number"
                                    component={FormTextInput}
                                    readOnly={_value?.new !== true}
                                  />
                                </FormRowAlt>
                                <FormDivider />
                                {values.tmcDetails[i].contactDetails.length ===
                                  idx + 1 && (
                                  <FormRowAlt>
                                    <AddButton
                                      idx={idx}
                                      insert={arrayHelpers.insert}
                                      btnText="Add More Contacts"
                                      rowObj={getContactDetailObj()}
                                    />
                                  </FormRowAlt>
                                )}
                              </React.Fragment>
                            )
                          );
                        }}
                      </FieldArray>
                    </FormSectionCard>
                    {values.tmcDetails.length === i + 1 && (
                      <FormRowAlt>
                        <AddButton
                          idx={i}
                          insert={insert}
                          btnText="Add TMC"
                          rowObj={getTmcDetailObj()}
                        />
                      </FormRowAlt>
                    )}
                  </React.Fragment>
                ));
              }}
            </FieldArray>
            <FormRow>
              <SubmitButton onClick={submitForm} />
            </FormRow>
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
};
