import React from 'react';
import Select from 'react-select';

export const PanSelect = ({ panArray, value, onPanSelect }) => {
  const options = React.useMemo(() => {
    return panArray
      ? panArray.map((pan) => ({
          value: pan,
          label: pan,
        }))
      : null;
  }, [panArray]);

  return (
    options && (
      <div className="select-wrapper" style={{ width: 150 }}>
        <Select
          placeholder="Select PAN"
          classNamePrefix="select"
          value={value || null}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          options={options}
          onChange={onPanSelect}
        />
      </div>
    )
  );
};
