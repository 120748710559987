import { axiosInstance } from './axiosInstance';
import { config } from '../utils';

export const getDocuments = async ({ currentPage, countPerPage, source }) => {
  return axiosInstance.get(
    `${config.dmsApiBaseUrl}/documents?currentPage=${currentPage}&perPage=${countPerPage}`,
    { ...(source && { cancelToken: source.token }) }
  );
};

export const getQueriedDocuments = async ({
  query,
  pan,
  currentPage,
  countPerPage,
  source,
}) => {
  return axiosInstance.get(
    `${config.dmsApiBaseUrl}/documents/search?query=${query}&currentPage=${currentPage}&perPage=${countPerPage}`,
    { ...(source && { cancelToken: source.token }) }
  );
};

export const getFilteredDocuments = async ({
  query,
  day,
  month,
  year,
  documentType,
  gstin,
  pan,
  currentPage,
  countPerPage,
  source,
}) => {
  const urlSearchParams = new URLSearchParams({
    ...(query && { query }),
    ...(day && { day }),
    ...(month && { month }),
    ...(year && { year }),
    ...(documentType && { documentType }),
    ...(gstin && { gstin }),
    ...(pan && { pan }),
    ...(currentPage && { currentPage }),
    ...(countPerPage && { perPage: countPerPage }),
  });
  const queryString = urlSearchParams.toString();

  return axiosInstance.get(
    `${config.dmsApiBaseUrl}/documents/filter?${queryString}`,
    { ...(source && { cancelToken: source.token }) }
  );
};
