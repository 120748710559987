import React from 'react';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';

import { FlexColumnContainer, FlexRowContainer } from '../Themed';
import { FaqCard } from './FaqCard';
import { Pagination } from '../Pagination';

const FaqSectionContainer = styled(FlexColumnContainer)`
  height: 654px;
  background-color: #fff;

  justify-content: center;
  align-items: center;
`;

const FaqSectionHeader = styled(FlexRowContainer.withComponent('header'))`
  height: 75px;
  max-width: 80%;

  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  & h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 55px;
  }

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 50px;

    & > p {
      font-size: 24px;
      line-height: 33px;

      color: #2176d5;
      cursor: pointer;
      margin-right: 50px;
    }
  }
`;

const FaqCardsContainer = styled(FlexRowContainer.withComponent('div'))`
  max-width: 80%;
  height: 380px;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  & div {
    margin-left: 65px;
  }

  & div:first-of-type {
    margin-left: 0;
  }
`;

export const FaqSection = React.memo(({ faqs, onViewAllClick }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [containerWidth, setContainerWidth] = React.useState(0);

  const location = useLocation();

  const faqSectionRef = React.useRef(null);
  const faqCardsContainerRef = React.createRef();

  React.useEffect(() => {
    if (location.hash === '#faqs' && faqSectionRef.current) {
      faqSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location, faqSectionRef]);

  React.useEffect(() => {
    if (faqCardsContainerRef.current) {
      const { width } = faqCardsContainerRef.current.getBoundingClientRect();
      const scrollWidth = faqCardsContainerRef.current.scrollWidth;

      setPageCount(Math.ceil(scrollWidth / width));
      setContainerWidth(width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollFaqs = React.useCallback(
    (scrollLeftMultiplier) => {
      faqCardsContainerRef.current.scrollTo({
        top: 0,
        left: scrollLeftMultiplier * containerWidth,
        behavior: 'smooth',
      });
    },
    [faqCardsContainerRef, containerWidth]
  );

  const onGotoStart = React.useCallback(() => {
    setCurrentPage(1);
    scrollFaqs(0);
  }, [setCurrentPage, scrollFaqs]);

  const onPrevious = React.useCallback(() => {
    const page = currentPage - 1;
    setCurrentPage(page);
    scrollFaqs(page - 1);
  }, [currentPage, setCurrentPage, scrollFaqs]);

  const onGotoPage = React.useCallback(
    (pageNumber) => {
      if (currentPage !== pageNumber) {
        setCurrentPage(pageNumber);
        scrollFaqs(pageNumber - 1);
      }
    },
    [currentPage, setCurrentPage, scrollFaqs]
  );

  const onNext = React.useCallback(() => {
    const page = currentPage + 1;
    setCurrentPage(page);
    scrollFaqs(page - 1);
  }, [currentPage, setCurrentPage, scrollFaqs]);

  const onGotoEnd = React.useCallback(() => {
    setCurrentPage(pageCount);
    scrollFaqs(pageCount - 1);
  }, [pageCount, setCurrentPage, scrollFaqs]);

  return (
    <FaqSectionContainer id="faqs" ref={faqSectionRef}>
      <FaqSectionHeader>
        <h1>FAQ's</h1>
        <div>
          <p onClick={onViewAllClick}>View all</p>
          <Pagination
            onGotoStart={onGotoStart}
            onPrevious={onPrevious}
            onGotoPage={onGotoPage}
            onNext={onNext}
            onGotoEnd={onGotoEnd}
            currentPage={currentPage}
            pages={pageCount}
          />
        </div>
      </FaqSectionHeader>
      <FaqCardsContainer ref={faqCardsContainerRef}>
        {faqs.map((faq, i) => (
          <FaqCard key={i} question={faq.question} answer={faq.answer} />
        ))}
      </FaqCardsContainer>
    </FaqSectionContainer>
  );
});
