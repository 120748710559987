import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { authService } from '../services';
import { logoutUser } from '../actions';

import NavbarSource from './@setproduct-ui/core/Navbar';
import SearchInput from './@setproduct-ui/core/Inputs/SearchInput';
import Button from './@setproduct-ui/core/Button';

import NavbarGroup from './@setproduct-ui/core/Navbar/Group';
import NavbarHeading from './@setproduct-ui/core/Navbar/Heading';

import ninjaLogo from '../assets/logos/Ninja-Logo.svg';

const NavbarWrapper = styled.div`
  .search-input {
    width: 512px;
  }
`;

export const Navbar = ({
  showSearch = true,
  showOpenIntercom = true,
  onSearch,
}) => {
  const dispatch = useDispatch();

  const onLogoutClick = React.useCallback(() => {
    try {
      dispatch(logoutUser());
      authService.logOut();
    } catch (error) {}
  }, [dispatch]);

  return (
    <NavbarWrapper>
      <NavbarSource type="desktop" view="smooth" color="default">
        <NavbarGroup
          align="left"
          style={{ marginRight: showSearch ? 0 : 'auto' }}
        >
          <Link to="/admin/companies">
            <img src={ninjaLogo} alt="" />
          </Link>
        </NavbarGroup>
        {showSearch && (
          <NavbarGroup align="center">
            <NavbarHeading>
              <SearchInput
                className="search-input"
                view="outlined"
                color="default"
                placeholder="Search Companies, PANs, GSTNs ..."
                onSearch={onSearch}
              />
            </NavbarHeading>
          </NavbarGroup>
        )}
        <NavbarGroup align="right">
          {showOpenIntercom && (
            <Button
              type="default"
              view="filled"
              color="primary"
              dense={false}
              text="Open Intercom"
              icon="people"
              rightIcon={true}
              loading={false}
              disabled={false}
              fill={false}
              onClick={() =>
                window.open(
                  'https://app.intercom.com/a/apps/tdyve18j/inbox/inbox/',
                  '_newtab'
                )
              }
              style={{
                marginRight: 20,
              }}
            />
          )}
          <Button
            type="default"
            view="outlined"
            color="default"
            dense={false}
            text="Logout"
            rightIcon="log-out"
            loading={false}
            disabled={false}
            fill={false}
            onClick={onLogoutClick}
          />
        </NavbarGroup>
      </NavbarSource>
    </NavbarWrapper>
  );
};
