import React from 'react';
import styled from 'styled-components/macro';

import { Icon } from '@blueprintjs/core';
import Table from '../../@setproduct-ui/core/Table/Table';
import { AccessActionButton } from './AccessActionButton';

const UserDetailsTableWrapper = styled.div``;

export const UserDetailsTable = ({
  userDetails,
  isLoading,
  grantAccessHandler,
  revokeAccessHandler,
}) => {
  const head = React.useMemo(
    () => [
      {
        id: 'id',
        label: 'Sr. No.',
      },
      {
        id: 'name',
        label: 'Name',
      },
      {
        id: 'email',
        label: 'Email',
      },
      {
        id: 'userType',
        label: 'User Type',
      },
      {
        id: 'invoiceAccess',
        label: 'User Approval Status',
      },
      {
        id: 'actionElement',
        label: 'Grant/Revoke Access',
      },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      userDetails.map((obj, i) => ({
        id: i + 1,
        ...obj,
        invoiceAccess: {
          0: (
            <span>
              <Icon icon="wrench" iconSize="18" />
              &nbsp;Pending Approval
            </span>
          ),
          1: (
            <span>
              <Icon icon="tick-circle" iconSize="18" />
              &nbsp;Approved
            </span>
          ),
        }[obj.invoiceAccess],
        actionElement: (
          <AccessActionButton
            hasAccess={obj.invoiceAccess === 1}
            isLoading={isLoading}
            grantAccessHandler={grantAccessHandler}
            revokeAccessHandler={revokeAccessHandler}
            accessHandlerProps={{ email: obj.email }}
          />
        ),
      })),
    [userDetails, isLoading, grantAccessHandler, revokeAccessHandler]
  );

  return (
    <UserDetailsTableWrapper>
      <Table
        name={head}
        data={data}
        position={new Array(6).fill('center')}
        type="def"
        view="smooth"
        color="primary"
        pages={false}
        checkboxes={false}
        handleRowClick={() => {}}
      />
    </UserDetailsTableWrapper>
  );
};
