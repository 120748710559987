import React from 'react';
import styled from 'styled-components/macro';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Input from '../@setproduct-ui/core/Inputs/DefaultInput';
import Button from '../@setproduct-ui/core/Button';

import { authService } from '../../services';

const LoginFormWrapper = styled.div`
  margin-top: 75px;

  .email-input-wrapper {
    margin-bottom: 20px;
  }

  .otp-input-wrapper {
    margin-bottom: 40px;
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  otp: Yup.string().length(4).required(),
});

export const LoginForm = ({ emailId, onLoginError }) => {
  const [isSendingOtp, setIsSendingOtp] = React.useState(false);
  const [otpSent, setOtpSent] = React.useState(false);
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const initialValues = React.useMemo(
    () => ({
      email: emailId ? emailId : '',
      otp: '1234',
    }),
    [emailId]
  );

  const handleGetOtp = async (email, setFieldValue) => {
    try {
      setIsSendingOtp(true);
      const result = await authService.emailLoginStart({ email });
      if (result) {
        setFieldValue('otp', '');
        setOtpSent(true);
        setIsSendingOtp(false);
      } else {
        setIsSendingOtp(false);
      }
    } catch (error) {
      setIsSendingOtp(false);
      console.log(error);
      onLoginError(error.description || 'Something went wrong');
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoggingIn(true);
      const result = await authService.emailLoginVerify({
        email: values.email,
        verificationCode: values.otp,
      });
      if (result) {
        setIsLoggingIn(false);
      } else {
        setIsLoggingIn(false);
      }
    } catch (error) {
      setIsLoggingIn(false);
      console.log(error);
      onLoginError(error.description || 'Something went wrong');
    }
  };

  return (
    <LoginFormWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(props) => (
          <Form key={emailId || 'form'}>
            <Field name="email">
              {({ field, meta }) => (
                <div className="email-input-wrapper">
                  {emailId ? (
                    <>
                      <Input
                        {...field}
                        type="text"
                        view="outlined"
                        color={
                          !!meta.touched && !!meta.error ? 'danger' : 'default'
                        }
                        label="Official Email"
                        placeholder="Official Email"
                        value={emailId}
                        error={!!meta.touched && !!meta.error}
                        fill={true}
                        errorHelperText={
                          !!meta.touched && !!meta.error ? meta.error : null
                        }
                        onInput={(e) => field.onChange(e)}
                        readOnly
                      />
                    </>
                  ) : (
                    <Input
                      {...field}
                      type="text"
                      view="outlined"
                      color={
                        !!meta.touched && !!meta.error ? 'danger' : 'default'
                      }
                      label="Official Email"
                      placeholder="Official Email"
                      value={field.value}
                      error={!!meta.touched && !!meta.error}
                      fill={true}
                      errorHelperText={
                        !!meta.touched && !!meta.error ? meta.error : null
                      }
                      onInput={(e) => field.onChange(e)}
                    />
                  )}
                </div>
              )}
            </Field>
            {otpSent && (
              <Field name="otp">
                {({ field, meta }) => (
                  <div className="otp-input-wrapper">
                    <Input
                      {...field}
                      type="password"
                      view="outlined"
                      dense={false}
                      color="default"
                      label="One Time Password"
                      placeholder="One Time Password"
                      value={field.value}
                      clearButton={false}
                      success={false}
                      error={!!meta.touched && !!meta.error}
                      fill={true}
                      errorHelperText={
                        !!meta.touched && !!meta.error ? meta.error : null
                      }
                      onInput={(e) => field.onChange(e)}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          props.submitForm();
                        }
                      }}
                    />
                  </div>
                )}
              </Field>
            )}
            {otpSent ? (
              <Button
                type="default"
                view="filled"
                color="primary"
                dense={false}
                text="Sign In"
                icon={false}
                rightIcon={false}
                loading={isLoggingIn}
                disabled={false}
                fill={true}
                onClick={props.submitForm}
              />
            ) : (
              <Button
                type="default"
                view="filled"
                color="default"
                dense={false}
                text="Get OTP"
                icon={false}
                rightIcon={false}
                loading={isSendingOtp}
                disabled={!props.isValid}
                fill={true}
                onClick={() =>
                  handleGetOtp(props.values.email, props.setFieldValue)
                }
              />
            )}
          </Form>
        )}
      </Formik>
    </LoginFormWrapper>
  );
};
