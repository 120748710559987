import styled from 'styled-components/macro';

export const Seperator = styled.div`
  border: 9px solid #7f2049;
  border-radius: 10px;
  width: 130px;
  position: relative;
  display: block;

  margin-top: 53px;
  margin-bottom: 45px;

  margin-left: ${(props) => (props.rtl ? '350px' : 0)};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: -9px;
    right: -70px;
    width: 40px;
    border: 9px solid #7f2049;
    border-radius: 10px;
  }
`;
