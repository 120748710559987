import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  FormContainer,
  FormRow,
  FormRowAlt,
  FormColumn,
  FormTextInput,
  FormRadioButtonGroup,
  SubmitButton,
} from '../../../components';
import { type1UserDetailsFormValidationSchema } from '../../../utils';
import { submitType1UserDetailsForm } from '../../../api';

const radioButtonGroup = [
  {
    key: 'Tax',
    value: 'tax',
  },
  {
    key: 'Legal',
    value: 'legal',
  },
  {
    key: 'Travel',
    value: 'travel',
  },
  {
    key: 'Others',
    value: 'others',
  },
];

const initialValues = {
  name: '',
  email: '',
  phoneNumber: '',
  userFunction: '',
  otherUserFunction: '',
  new: true,
};

export const Type1UserDetailsForm = ({
  userData,
  formValue,
  onSuccess,
  onError,
  ...props
}) => {
  const emailId = useSelector((state) => state.auth.emailId);

  const initialFormValues = React.useMemo(() => {
    return formValue?.userDetails &&
      Object.keys(formValue.userDetails).length > 0
      ? formValue.userDetails
      : {
          ...initialValues,
          name: userData?.user_metadata?.userName || '',
          email: emailId || '',
          phoneNumber: userData?.user_metadata?.phoneNumber.substr(3) || '',
        };
  }, [formValue, userData, emailId]);

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const { data } = await submitType1UserDetailsForm({
        form: { uuid: uuidv4(), ...values },
      });
      console.log(data);
      onSuccess({ msg: 'Saved!' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialFormValues}
        validationSchema={type1UserDetailsFormValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, submitForm }) => (
          <Form key={values._id || 'form'}>
            <FormContainer>
              <FormRowAlt>
                <h4>Enter your personal details</h4>
              </FormRowAlt>
              <FormRowAlt style={{ alignItems: 'flex-start' }}>
                <FormColumn>
                  <FormRowAlt>
                    <Field
                      name="name"
                      label="User Name"
                      component={FormTextInput}
                      readOnly
                    />
                  </FormRowAlt>
                  <FormRowAlt>
                    <Field
                      name="email"
                      label="Email"
                      component={FormTextInput}
                      readOnly
                    />
                  </FormRowAlt>
                  <FormRowAlt>
                    <Field
                      name="phoneNumber"
                      label="Phone Number"
                      component={FormTextInput}
                      readOnly
                    />
                  </FormRowAlt>
                </FormColumn>
                <FormColumn>
                  <FormRowAlt
                    style={{ alignItems: 'flex-start', marginTop: 5 }}
                  >
                    <FormRadioButtonGroup
                      name="userFunction"
                      headingText="Select Function"
                      options={radioButtonGroup}
                      readOnly={values?.new !== true}
                    />
                  </FormRowAlt>
                  {values.userFunction === 'others' && (
                    <FormRowAlt>
                      <Field
                        name="otherUserFunction"
                        label="Other Function"
                        component={FormTextInput}
                        readOnly={values?.new !== true}
                      />
                    </FormRowAlt>
                  )}
                </FormColumn>
              </FormRowAlt>
              <FormRow>
                <SubmitButton onClick={() => submitForm()} />
              </FormRow>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};
