import React from 'react';
import styled from 'styled-components/macro';
import { format } from 'date-fns';

import { Icon } from '@blueprintjs/core';
import Table from '../../@setproduct-ui/core/Table/Table';
import { AccessActionButton } from './AccessActionButton';

const PanDetailsTableWrapper = styled.div``;

export const PanDetailsTable = ({
  panDetails,
  isLoading,
  grantAccessHandler,
  revokeAccessHandler,
}) => {
  const head = React.useMemo(
    () => [
      {
        id: 'id',
        label: 'Sr. No.',
      },
      {
        id: 'entity',
        label: 'Entity Name',
      },
      {
        id: 'GSTResponseCompanyName',
        label: 'GST API Response',
      },
      {
        id: 'invoiceAccess',
        label: 'PAN Approval Status',
      },
      {
        id: 'panNumber',
        label: 'PAN Number',
      },
      {
        id: 'folderID',
        label: 'Folder ID',
      },
      {
        id: 'createdTime',
        label: 'Created On',
      },
      {
        id: 'actionElement',
        label: 'Grant/Revoke Access',
      },
    ],
    []
  );

  const data = React.useMemo(
    () =>
      panDetails.map((obj, i) => ({
        id: i + 1,
        ...obj,
        invoiceAccess: {
          0: (
            <span>
              <Icon icon="wrench" iconSize="18" />
              &nbsp;Pending Approval
            </span>
          ),
          1: (
            <span>
              <Icon icon="tick-circle" iconSize="18" />
              &nbsp;Approved
            </span>
          ),
        }[obj.invoiceAccess],
        createdTime: format(new Date(obj.createdTime), 'dd MMM yyyy'),
        actionElement: (
          <AccessActionButton
            hasAccess={obj.invoiceAccess === 1}
            isLoading={isLoading}
            grantAccessHandler={grantAccessHandler}
            revokeAccessHandler={revokeAccessHandler}
            accessHandlerProps={{ pan: obj.panNumber }}
          />
        ),
      })),
    [panDetails, isLoading, grantAccessHandler, revokeAccessHandler]
  );

  return (
    <PanDetailsTableWrapper>
      <Table
        name={head}
        data={data}
        position={new Array(7).fill('center')}
        type="def"
        view="smooth"
        color="primary"
        pages={false}
        checkboxes={false}
        handleRowClick={() => {}}
      />
    </PanDetailsTableWrapper>
  );
};
