import { axiosInstance } from './axiosInstance';

export const getOtp = ({ emailId = null, mobileNumber = null }) => {
  const body = {
    ...(emailId && { channel: 'email' }),
    ...(emailId && { emailId }),
    ...(mobileNumber && { channel: 'sms' }),
    ...(mobileNumber && { mobileNumber }),
  };
  return axiosInstance.post('/user-info-validation/start', body);
};

export const verifyOtp = ({
  emailId = null,
  mobileNumber = null,
  otp = null,
}) => {
  const body = {
    ...(emailId && { channel: 'email' }),
    ...(emailId && { emailId }),
    ...(mobileNumber && { channel: 'sms' }),
    ...(mobileNumber && { mobileNumber }),
    code: otp,
  };

  return axiosInstance.post('/user-info-validation/verify', body);
};

export const getRegistrationInfo = () => {
  return axiosInstance.get('/registration');
};

export const registerNewUser = ({ form }) => {
  return axiosInstance.post('/registration', form);
};

export const submitType1CompanyDetailsForm = ({ form }) => {
  return axiosInstance.post('/registration/forms/company-details/type-1', form);
};

export const submitType2CompanyDetailsForm = ({ form }) => {
  return axiosInstance.post('/registration/forms/company-details/type-2', form);
};

export const submitType1UserDetailsForm = ({ form }) => {
  return axiosInstance.post('/registration/forms/user-details/type-1', form);
};

export const submitType2UserDetailsForm = ({ form }) => {
  return axiosInstance.post('/registration/forms/user-details/type-2', form);
};

export const submitTmcDetailsForm = ({ form }) => {
  return axiosInstance.post('/registration/forms/tmc-details', form);
};

export const submitClientDetailsForm = ({ form }) => {
  return axiosInstance.post('/registration/forms/client-details', form);
};

export const submitAlternateContactsForm = ({ form }) => {
  return axiosInstance.post('/registration/forms/alternate-contacts', form);
};

export const parseClientFile = ({ file }) => {
  const formData = new FormData();
  formData.append('file', file);

  return axiosInstance.post('/registration/files/clients/parse', formData);
};
