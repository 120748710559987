import { showLoader, hideLoader } from './loaderActions';
import { authService } from '../services';

export const SET_LOGGEDIN_USER = 'SET_LOGGEDIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const setLoggedInUser = (payload) => ({
  type: SET_LOGGEDIN_USER,
  payload,
});

export const logoutUser = (payload) => ({
  type: LOGOUT_USER,
});

export const trySilentLogin = () => async (dispatch) => {
  if (authService.isTokenValid()) {
    dispatch(showLoader());
    const localAccessToken = localStorage.getItem('accessToken');
    const user = await authService.getUserInfo({
      accessToken: localAccessToken,
    });
    dispatch(
      setLoggedInUser({
        didSilentLogin: true,
        accessToken: localAccessToken,
        emailId: user.email,
        auth0Result: {},
        auth0User: user,
      })
    );
    dispatch(hideLoader());
  } else {
    localStorage.clear();
  }
};
