import React from 'react';
import styled from 'styled-components/macro';
import { Formik, Form, FieldArray, Field } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { Icon } from '@blueprintjs/core';

import {
  FlexRowContainer,
  FormContainer,
  FormRow,
  FormRowAlt,
  FormTextInput,
  Delete,
  AddButton,
  SubmitButton,
  FormSectionCard,
} from '../../../components';
import {
  clientDetailsFormValidationSchema,
  validateEmailField,
  validatePhoneNumberField,
} from '../../../utils';
import { parseClientFile, submitClientDetailsForm } from '../../../api';

import clientsTemplate from '../../../assets/documents/clients-template.csv';

const ClientDetailsFormWrapper = styled.div``;

const getClientObj = () => ({
  uuid: uuidv4(),
  name: '',
  email: '',
  phoneNumber: '',
  gstNumber: '',
  new: true,
});

const initialValues = {
  clientDetails: [getClientObj()],
};

export const ClientDetailsForm = ({
  formValue,
  onSuccess,
  onError,
  ...props
}) => {
  const [newFormValues, setNewFormValues] = React.useState([]);

  const initialFormValues = React.useMemo(() => {
    return formValue?.clientDetails && formValue.clientDetails.length > 0
      ? {
          clientDetails: [...formValue.clientDetails, ...newFormValues],
        }
      : { clientDetails: [...initialValues.clientDetails, ...newFormValues] };
  }, [formValue, newFormValues]);

  const handleFileUpload = async (e) => {
    try {
      const files = [...e.target.files];
      const file = files[0];
      if (file) {
        const { data } = await parseClientFile({ file });
        console.log(data);
        setNewFormValues(data.arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const { data } = await submitClientDetailsForm({ form: values });
      console.log(data);
      onSuccess({ msg: 'Saved!' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ClientDetailsFormWrapper>
      <Formik
        initialValues={initialFormValues}
        validationSchema={clientDetailsFormValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ values, submitForm }) => (
          <Form>
            <FormContainer>
              <FormRowAlt style={{ justifyContent: 'space-between' }}>
                <h4>Your Client Details</h4>
                <FlexRowContainer
                  style={{ alignItems: 'center', width: 'auto' }}
                >
                  <Icon
                    icon="cloud-download"
                    iconSize={24}
                    style={{ marginRight: 10 }}
                  />
                  <a
                    href={clientsTemplate}
                    download="clients.csv"
                    style={{ fontSize: 14 }}
                  >
                    Download CSV Template
                  </a>
                </FlexRowContainer>
                <FlexRowContainer
                  style={{ alignItems: 'center', width: 'auto' }}
                >
                  <Icon
                    icon="cloud-upload"
                    iconSize={24}
                    style={{ marginRight: 10 }}
                  />
                  <label
                    htmlFor="client-list"
                    style={{ cursor: 'pointer', fontSize: 14 }}
                  >
                    Upload Clients List
                  </label>
                  <input
                    type="file"
                    accept=".csv"
                    multiple={false}
                    id="client-list"
                    name="client-list"
                    style={{ display: 'none' }}
                    onInput={handleFileUpload}
                  />
                </FlexRowContainer>
              </FormRowAlt>
              <FieldArray name="clientDetails">
                {({ insert, remove }) => {
                  return values.clientDetails.map((value, i) => (
                    <React.Fragment key={value.uuid}>
                      <FormSectionCard>
                        <FormRowAlt>
                          <Field
                            name={`clientDetails.${i}.name`}
                            label="Client Name"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                          />
                          <Field
                            name={`clientDetails.${i}.gstNumber`}
                            label="GST Number"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                          />
                          {values.clientDetails.length > 1 &&
                            value?.new === true && (
                              <Delete idx={i} remove={remove} />
                            )}
                        </FormRowAlt>
                        <FormRowAlt>
                          <Field
                            name={`clientDetails.${i}.email`}
                            label="Email"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validateEmailField(value?.new)}
                          />
                          <Field
                            name={`clientDetails.${i}.phoneNumber`}
                            label="Phone Number"
                            component={FormTextInput}
                            readOnly={value?.new !== true}
                            validate={validatePhoneNumberField(value?.new)}
                          />
                        </FormRowAlt>
                      </FormSectionCard>
                      {values.clientDetails.length === i + 1 && (
                        <FormRowAlt>
                          <AddButton
                            idx={i}
                            insert={insert}
                            btnText="Add Client"
                            rowObj={getClientObj()}
                          />
                        </FormRowAlt>
                      )}
                    </React.Fragment>
                  ));
                }}
              </FieldArray>
              <FormRow>
                <SubmitButton onClick={submitForm} />
              </FormRow>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </ClientDetailsFormWrapper>
  );
};
