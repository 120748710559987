import React from 'react';
import styled from 'styled-components/macro';

import { FlexColumnContainer } from '../Themed';

const FaqCardContainer = styled(FlexColumnContainer.withComponent('div'))`
  background-color: #f4f6f7;
  min-width: 320px;
  max-width: 320px;
  height: 360px;
  border-radius: 10px;

  justify-content: flex-start;
  align-items: center;
`;

const Question = styled.p`
  width: 256px;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  margin: 40px 0 25px 0;
`;

const Answer = styled.p`
  width: 256px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const FaqCard = React.memo(({ question, answer }) => {
  return (
    <FaqCardContainer>
      <Question>{question}</Question>
      <Answer>{answer}</Answer>
    </FaqCardContainer>
  );
});
