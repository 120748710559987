import React from 'react';
import styled from 'styled-components/macro';

import { FlexContainer, FlexRowContainer } from './Themed';

import { ReactComponent as LeftArrowEnd } from '../assets/icons/left-arrow-end.svg';
import { ReactComponent as LeftArrow } from '../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrowEnd } from '../assets/icons/right-arrow-end.svg';
import { ReactComponent as RightArrow } from '../assets/icons/right-arrow.svg';

const PaginationContainer = styled(FlexRowContainer.withComponent('div'))`
  height: 22px;
  width: 232px;

  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;

const ActionIcon = styled(FlexContainer.withComponent('button'))`
  width: 20px;
  height: 20px;
  background-color: #eff5fc;
  border-radius: 1px;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  border: none;
`;

const PageNumber = styled.p`
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  opacity: ${(props) => (props.isCurrent ? 1 : 0.5)};
  cursor: pointer;
`;

export const Pagination = React.memo(
  ({
    onGotoStart = () => {},
    onPrevious = () => {},
    onGotoPage = () => {},
    onNext = () => {},
    onGotoEnd = () => {},
    currentPage = 1,
    pages = 1,
  }) => (
    <PaginationContainer>
      <ActionIcon disabled={currentPage === 1} onClick={onGotoStart}>
        <LeftArrowEnd />
      </ActionIcon>
      <ActionIcon disabled={currentPage === 1} onClick={onPrevious}>
        <LeftArrow />
      </ActionIcon>
      {new Array(pages)
        .fill(0)
        .map((_, i) => i + 1)
        .map((page, i) => (
          <PageNumber
            key={i}
            isCurrent={page === currentPage}
            onClick={() => onGotoPage(page)}
          >
            {page}
          </PageNumber>
        ))}
      <ActionIcon disabled={currentPage === pages} onClick={onNext}>
        <RightArrow />
      </ActionIcon>
      <ActionIcon disabled={currentPage === pages} onClick={onGotoEnd}>
        <RightArrowEnd />
      </ActionIcon>
    </PaginationContainer>
  )
);
