import React from 'react';
import Select from 'react-select';

export const GstinSelect = ({ gstinArray, value, onGstinSelect }) => {
  const options = React.useMemo(() => {
    return gstinArray
      ? gstinArray.map((pan) => ({
          value: pan,
          label: pan,
        }))
      : null;
  }, [gstinArray]);

  return (
    options && (
      <div className="select-wrapper" style={{ width: 180 }}>
        <Select
          placeholder="Select GSTIN"
          classNamePrefix="select"
          value={value || null}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          options={options}
          onChange={onGstinSelect}
        />
      </div>
    )
  );
};
