import styled from 'styled-components/macro';

const defaultFlexStyles = {
  width: '100%',
  height: 'auto',
  display: 'flex !important',
};

export const FlexContainer = styled.section`
  ${defaultFlexStyles}
`;

export const FlexRowContainer = styled.section`
  ${defaultFlexStyles};
  flex-direction: row;
`;

export const FlexColumnContainer = styled(FlexContainer)`
  ${defaultFlexStyles}
  flex-direction: column;
`;
