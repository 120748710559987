import React from 'react';
import styled from 'styled-components/macro';

import Card from '../../@setproduct-ui/core/Card';

const CountCardWrapper = styled.div`
  .count-card {
    cursor: pointer;
    width: 330px;
    height: 120px;
    box-sizing: border-box;
    border: ${({ highlighted }) =>
      highlighted ? '2px solid var(--color50)' : '2px solid var(--color0)'};

    & > div {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;

      & > p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0275em;
        margin: 0;
        font-feature-settings: 'tnum' on, 'lnum' on, 'ss01' on;
        color: var(--grey60);
      }

      & > h2 {
        font-weight: bold;
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -0.025em;
        margin: 0;
        color: #000;
      }
    }
  }
`;

export const CountCard = React.memo(
  ({
    id,
    selectedCard,
    view = 'smooth',
    color = 'primary',
    interactive = false,
    title,
    count,
    onClick,
  }) => {
    return (
      <CountCardWrapper highlighted={selectedCard === id}>
        <Card
          className="count-card"
          view={view}
          color={color}
          interactive={interactive}
          onClick={() => (selectedCard === id ? null : onClick(id))}
        >
          <div>
            <p>{title}</p>
            <h2>{count}</h2>
          </div>
        </Card>
      </CountCardWrapper>
    );
  }
);
