import React from 'react';
import styled from 'styled-components/macro';

import { FlexRowContainer } from '../Themed';

const Header = styled(FlexRowContainer.withComponent('header'))`
  width: 740px;
  height: 45px;

  justify-content: space-evenly;
  align-items: center;

  background: #ffffff;
  box-shadow: 0.555974px 1.11195px 4.44779px rgba(173, 173, 173, 0.300126);
`;

const Tab = styled.p`
  flex: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${(props) => (props.isSelected ? 600 : 400)};
  border-bottom: ${(props) => (props.isSelected ? '2px solid black' : 'none')};
  cursor: pointer;
  margin-bottom: 0;
  transition: all 0.075s ease-in-out;
`;

const VerticalDivider = styled.div`
  height: 30px;
  width: 1px;
  mix-blend-mode: normal;
  opacity: 0.2;
  border: 0.55px solid #979797;
`;

export const RegistrationHeader = ({
  tabs,
  currentTabIndex = 0,
  onTabClick,
}) => {
  return (
    <Header>
      {tabs.map((tab, i) => (
        <React.Fragment key={i}>
          <Tab isSelected={currentTabIndex === i} onClick={() => onTabClick(i)}>
            {tab}
          </Tab>
          {tabs.length !== i + 1 && <VerticalDivider />}
        </React.Fragment>
      ))}
    </Header>
  );
};
