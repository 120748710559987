import React from 'react';
import Select from 'react-select';

const options = [
  { value: 'invoice', label: 'Invoice' },
  { value: 'credit note', label: 'Credit Note' },
  { value: 'debit note', label: 'Debit Note' },
];

export const DocumentTypeSelect = ({ value, onDocumentTypeSelect }) => {
  return (
    <div className="select-wrapper" style={{ width: 200 }}>
      <Select
        placeholder="Document Type"
        classNamePrefix="select"
        value={value || null}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        options={options}
        onChange={onDocumentTypeSelect}
      />
    </div>
  );
};
