import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import './components/@setproduct-ui/styles/setproduct.css';

import { trySilentLogin } from './actions';
import {
  LoginContainer,
  CallbackContainer,
  LandingContainer,
} from './containers';
import { ProtectedRoute, Loader } from './components';

const CompaniesContainer = React.lazy(() =>
  import('./containers/CompaniesContainer')
);
const CompanyContainer = React.lazy(() =>
  import('./containers/CompanyContainer')
);

const RegistrationContainer = React.lazy(() =>
  import('./containers/RegistrationContainer')
);

const UserSignupContainer = React.lazy(() =>
  import('./containers/UserSignupContainer')
);

const UserDashboardContainer = React.lazy(() =>
  import('./containers/UserDashboardContainer')
);

const DocumentsContainer = React.lazy(() =>
  import('./containers/DocumentsContainer')
);

const HotelsContainer = React.lazy(() =>
  import('./containers/HotelsContainer')
);

const App = () => {
  const showLoader = useSelector((state) => state.showLoader);
  const { isUserLoggedIn, didSilentLogin } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    dispatch(trySilentLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isUserLoggedIn && didSilentLogin) {
      history.push(`/dashboard`);
    }
  }, [isUserLoggedIn, history, didSilentLogin]);

  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/" exact component={LandingContainer} />
          <Route exact path="/login" component={LoginContainer} />
          <Route path="/callback" exact component={CallbackContainer} />
          <Route path="/signup" exact component={UserSignupContainer} />
          <ProtectedRoute
            path="/registration"
            component={RegistrationContainer}
          />
          <ProtectedRoute
            path="/dashboard"
            exact
            component={UserDashboardContainer}
          />
          <Route path="/documents" exact component={DocumentsContainer} />
          <ProtectedRoute
            exact
            path="/admin/companies"
            component={CompaniesContainer}
          />
          <ProtectedRoute
            exact
            path="/admin/companies/:id"
            component={CompanyContainer}
          />
          <Route path="/hotels" component={HotelsContainer} />
          <Redirect to="/login" />
        </Switch>
        {showLoader && <Loader />}
      </React.Suspense>
    </>
  );
};

export default App;
