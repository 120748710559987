import React from 'react';
import Select from 'react-select';

const options = [
  { value: 2018, label: '2018' },
  { value: 2019, label: '2019' },
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
];

export const YearSelect = ({ value, onYearSelect }) => {
  return (
    <div className="select-wrapper" style={{ width: 150 }}>
      <Select
        placeholder="Select Year"
        classNamePrefix="select"
        value={value || null}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        options={options}
        onChange={onYearSelect}
      />
    </div>
  );
};
