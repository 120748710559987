import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@blueprintjs/core';

import { Section, FaqSection, FaqModal } from '../components';

import { validateOfficialEmail } from '../utils/validateOfficialEmail';
import { checkUserExists } from '../api';
import usePrevious from '../hooks/usePrevious';

import Input from '../components/@setproduct-ui/core/Inputs/DefaultInput';
import Button from '../components/@setproduct-ui/core/Button';
import Toaster from '../components/@setproduct-ui/core/Toast/Toaster';

import landingBg from '../assets/backgrounds/landing-bg.png';
import qatarLogo from '../assets/logos/qatar-logo.svg';
import finkraftLogo from '../assets/logos/finkraft-logo.svg';
import section1Image from '../assets/images/home-section-1.png';
import section2Image from '../assets/images/home-section-2.png';

import faqs from '../assets/documents/faqs.json';

const LandingContainerWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #fff;

  .hero {
    height: 100vh;
    background-color: #fff;
    background: url(${landingBg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & .row {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .logos {
    justify-content: space-between;

    & > img {
      padding-top: 30px;

      &:nth-of-type(1) {
        padding-left: 40px;
      }

      &:nth-of-type(2) {
        padding-right: 40px;
      }
    }
  }

  & .main {
    margin-top: 65vh;
  }

  & .text-content {
    margin-top: 35px;
    flex-direction: column;
    font-family: Merriweather;

    text-align: center;
    color: #000000;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);

    h2 {
      font-weight: bold;
      font-size: 28px;
      line-height: 35px;
    }

    p {
      margin-top: 10px;
      font-weight: normal;
      font-size: 24px;
      line-height: 30px;
    }
  }
`;

export const LandingContainer = (props) => {
  const [inputValue, setInputValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [myRef, setRef] = React.useState(null);

  const [displayFaqsModal, setDisplayFaqsModal] = React.useState(false);

  const showFaqsModal = React.useCallback(() => {
    setDisplayFaqsModal(true);
  }, [setDisplayFaqsModal]);

  const hideFaqsModal = React.useCallback(() => {
    setDisplayFaqsModal(false);
  }, [setDisplayFaqsModal]);

  const previousErrorMsg = usePrevious(errorMsg);

  React.useEffect(() => {
    if (errorMsg && previousErrorMsg !== errorMsg) {
      myRef.show({
        message: errorMsg,
        icon: <Icon icon="info-sign" iconSize={24} />,
      });
    }
  }, [previousErrorMsg, errorMsg, myRef]);

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = async () => {
    try {
      setErrorMsg(null);
      setIsLoading(true);
      const validEmailAddress = await validateOfficialEmail(inputValue);
      const { data } = await checkUserExists({ emailId: validEmailAddress });
      const userExists = data.userExists;
      setIsLoading(false);
      if (userExists) {
        props.history.push(`/login?emailId=${inputValue}`);
      } else {
        props.history.push({
          pathname: '/signup',
          state: { emailId: inputValue },
        });
      }
    } catch (error) {
      console.error(error);
      setErrorMsg(error);
      setIsLoading(false);
    }
  };

  return (
    <LandingContainerWrapper>
      <div className="hero">
        <div className="row logos">
          <img src={qatarLogo} alt="" />
          <img src={finkraftLogo} alt="" />
        </div>
        <div className="row main">
          <div>
            <Input
              style={{
                width: 600,
                height: 60,
                boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25)',
                borderRadius: 20,
              }}
              type="text"
              view="raised"
              color="default"
              value={inputValue}
              loading={false}
              placeholder="Enter Company Email"
              onInput={handleInput}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleButtonClick();
                }
              }}
            />
          </div>
          <Button
            style={{
              marginLeft: 40,
              boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.25)',
              borderRadius: 20,
            }}
            type="action"
            view="raised"
            color="success"
            text="Get Started"
            loading={isLoading}
            onClick={handleButtonClick}
          />
        </div>
        <div className="row text-content">
          <h2>Your one stop solution to download tax invoices</h2>
          <p>for Corporates, TMCs, OTAs and Cargo</p>
        </div>
      </div>
      <Section
        sectionIndex={1}
        headerText="We value your money"
        text="Download Airline Tax Invoices for the tickets booked & timely file your GST return."
        image={section1Image}
        backgroundColor="#f4f6f7"
      />
      <Section
        sectionIndex={2}
        headerText="Download all your GST invoices"
        text="Get all your GST invoices at a single place in a predefined folder structure."
        image={section2Image}
        backgroundColor="#fff"
        rtl={true}
      />
      <Section
        sectionIndex={3}
        headerText="We are here to help"
        text="Enter your email ID to view reconciled dashboards and reports for your transactions"
        image={section1Image}
        backgroundColor="#f4f6f7"
      />
      <FaqSection faqs={faqs} onViewAllClick={showFaqsModal} />
      <FaqModal
        faqs={faqs}
        isOpen={displayFaqsModal}
        onRequestClose={hideFaqsModal}
      />
      <Toaster
        type="def"
        view="outlined"
        color="danger"
        usePortal={true}
        position="top"
        ref={(ref) => setRef(ref)}
        maxToasts={1}
        withoutClose={false}
      />
    </LandingContainerWrapper>
  );
};
