import React from 'react';
import styled from 'styled-components/macro';
import { format } from 'date-fns';

import { Icon } from '@blueprintjs/core';
import Table from '../../@setproduct-ui/core/Table/Table';

const CompanyDetailsTableWrapper = styled.div``;

export const CompanyDetailsTable = ({
  companyName = '',
  createdBy = '',
  createdAt = new Date('1970-01-01'),
  companyWebsite = '',
  businessType = '',
  entityName = '',
  invoiceAccess,
  folderIds = '',
}) => {
  const head = React.useMemo(
    () => [
      {
        id: 'companyName',
        label: 'Company Name',
      },
      {
        id: 'createdBy',
        label: 'Created By',
      },
      {
        id: 'createdAt',
        label: 'Created On',
      },
      {
        id: 'folderIds',
        label: 'Folder IDs',
      },
      {
        id: 'companyWebsite',
        label: 'Company Website',
      },
      {
        id: 'businessType',
        label: 'Business Type',
      },
      {
        id: 'entityName',
        label: 'Entity Name',
      },
      {
        id: 'approvalStatus',
        label: 'Company Approval Status',
      },
    ],
    []
  );

  const data = React.useMemo(
    () => [
      {
        companyName,
        createdBy,
        createdAt: format(new Date(createdAt), 'dd MMM yyyy'),
        companyWebsite,
        businessType,
        entityName,
        approvalStatus: {
          0: (
            <span>
              <Icon icon="wrench" iconSize="18" />
              &nbsp;Pending Approval
            </span>
          ),
          1: (
            <span>
              <Icon icon="tick-circle" iconSize="18" />
              &nbsp;Approved
            </span>
          ),
        }[invoiceAccess],
        folderIds,
      },
    ],
    [
      companyName,
      createdBy,
      createdAt,
      companyWebsite,
      businessType,
      entityName,
      invoiceAccess,
      folderIds,
    ]
  );

  return (
    <CompanyDetailsTableWrapper>
      <Table
        name={head}
        data={data}
        position={new Array(8).fill('center')}
        type="def"
        view="smooth"
        color="primary"
        pages={false}
        checkboxes={false}
        handleRowClick={() => {}}
      />
    </CompanyDetailsTableWrapper>
  );
};
