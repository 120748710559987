import React from 'react';
import { useDispatch } from 'react-redux';

import { setLoggedInUser, hideLoader, showLoader } from '../actions';

import { authService } from '../services';

export const CallbackContainer = (props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async function () {
      try {
        dispatch(showLoader());
        const result = await authService.getAuthResult();
        localStorage.setItem('accessToken', result.accessToken);
        localStorage.setItem(
          'expiresAt',
          new Date().getTime() + Number(result.expiresIn) * 1000
        );
        const user = await authService.getUserInfo({
          accessToken: result.accessToken,
        });

        localStorage.setItem('email', user.email);
        dispatch(
          setLoggedInUser({
            accessToken: result.accessToken,
            emailId: user.email,
            auth0Result: result,
            auth0User: user,
          })
        );
        props.history.replace(`/dashboard`);
        dispatch(hideLoader());
      } catch (error) {
        dispatch(hideLoader());
        props.history.replace('/');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
