import { axiosInstance } from './axiosInstance';

export const checkUserExists = ({ emailId = null, phoneNumber = null }) => {
  return axiosInstance.post('/user/validate', {
    ...(emailId && { emailId }),
    ...(phoneNumber && { phoneNumber }),
  });
};

export const fetchAuth0UserData = ({ emailId }) => {
  return axiosInstance.post('/user', { emailId });
};
