import React from 'react';
import styled from 'styled-components/macro';
import { Field } from 'formik';
import { Icon } from '@blueprintjs/core';

import Input from './@setproduct-ui/core/Inputs/DefaultInput';
import Button from './@setproduct-ui/core/Button';
import Radio from './@setproduct-ui/core/Radio';

import { FlexColumnContainer, FlexRowContainer } from './Themed';
import { BusinessTypesContainer } from '../containers/BusinessTypesContainer';

const FormInputWrapper = styled(FlexColumnContainer.withComponent('div'))`
  width: 285px;
  flex-wrap: nowrap;
  position: relative;

  & > label {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 7px;
    font-weight: 600;

    color: #000000;
  }

  & > input {
    height: 30px;
    background: #ffffff;
    border: 1.11195px solid #e9edf3;
    border-radius: 3.33584px;
    padding: 0 5px;
  }
`;

export const ErrorMsg = styled.p`
  font-size: 12px;
  line-height: 18px;

  color: #d90000;
  margin-top: 5px;
`;

export const FormTextInput = ({ field, form, ...props }) => {
  const meta = form.getFieldMeta(field.name);

  return (
    <FormInputWrapper className="input-wrapper">
      <Input
        {...field}
        {...props}
        type="text"
        view="smooth"
        dense={false}
        color="primary"
        label={props.label}
        value={props.value || field.value}
        placeholder={'Enter ' + props.label}
        clearButton={false}
        success={false}
        error={!!meta.touched && !!meta.error}
        fill={false}
        errorHelperText={!!meta.touched && !!meta.error ? meta.error : null}
        onInput={(e) => field.onChange(e)}
      />
    </FormInputWrapper>
  );
};

export const FormRadioButtonGroup = ({
  name,
  options,
  headingText,
  ...props
}) => {
  return (
    <Field name={name} {...props}>
      {({ field, meta }) => (
        <div style={{ width: '100%' }}>
          <h4 style={{ marginBottom: 15, marginTop: '-5px' }}>{headingText}</h4>
          {options.map((option, i) => (
            <Radio
              key={i}
              {...field}
              type="dense"
              color="primary"
              value={option.value}
              checked={field.value === option.value}
              label={option.key}
            />
          ))}
          {!!meta.touched && !!meta.error && <ErrorMsg>{meta.error}</ErrorMsg>}
        </div>
      )}
    </Field>
  );
};

const BusinessTypeSelectionWrapper = styled.div`
  margin-right: 0px !important;
  width: 100%;

  & > label {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }
`;

export const BusinessTypeSelection = ({ field, form, ...props }) => {
  const meta = form.getFieldMeta(field.name);

  return (
    <BusinessTypeSelectionWrapper>
      <label>{props.label}</label>
      <BusinessTypesContainer
        selectedCard={field.value}
        onCardClick={(cardTitle) => form.setFieldValue(field.name, cardTitle)}
      />
      {!!meta.touched && !!meta.error && <ErrorMsg>{meta.error}</ErrorMsg>}
    </BusinessTypeSelectionWrapper>
  );
};

export const Delete = ({ idx, remove }) => {
  return (
    <Icon
      style={{
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 3,
        cursor: 'pointer',
        color: 'var(--red50)',
      }}
      icon="trash"
      iconSize={28}
      onClick={() => remove(idx)}
    />
  );
};

export const AddButton = ({ idx, insert, btnText, rowObj }) => {
  return (
    <Button
      type="default"
      dense={true}
      view="smooth"
      color="primary"
      icon="plus"
      onClick={() => insert(idx + 1, rowObj)}
      disabled={false}
    >
      {btnText}
    </Button>
  );
};

export const SubmitButton = ({ onClick }) => {
  return (
    <Button
      style={{ width: 200 }}
      type="action"
      view="filled"
      color="primary"
      onClick={onClick}
      disabled={false}
    >
      Save
    </Button>
  );
};

const BannerWrapper = styled(FlexRowContainer.withComponent('div'))`
  width: 95%;
  justify-content: center;
  align-items: center;

  & * {
    color: #666;
  }

  & p {
    white-space: pre-line;
  }
`;

export const Banner = ({ bannerText = '' }) => {
  return (
    <BannerWrapper>
      <Icon icon="warning-sign" iconSize={40} style={{ marginRight: 20 }} />
      <div>
        <h4>Why its important?</h4>
        <p>{bannerText}</p>
      </div>
    </BannerWrapper>
  );
};
